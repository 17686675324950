// src/components/common/CookieConsentBar.jsx
import React, { useState, useEffect } from 'react';

import CustomizeCookiesModal from '../modals/CustomizeCookiesModal';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../../assets/styles/CookieConsentBar.css';

const CookieConsentBar = () => {
  const { t } = useTranslation();

  const [isVisible, setIsVisible] = useState(false);
  const [showCustomizeModal, setShowCustomizeModal] = useState(false);
  const language = localStorage.getItem('language') || 'en';

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAcceptAll = () => {
    localStorage.setItem(
      'cookieConsent',
      JSON.stringify({
        essential: true,
        performance: true,
        functionality: true,
        advertising: true,
      })
    );
    setIsVisible(false);
  };

  const handleAcceptEssential = () => {
    localStorage.setItem(
      'cookieConsent',
      JSON.stringify({
        essential: true,
        performance: false,
        functionality: false,
        advertising: false,
      })
    );
    setIsVisible(false);
  };

  const handleCustomize = () => {
    setShowCustomizeModal(true);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <div className="cookie-consent-bar">
        <p>
          <Trans i18nKey="cookieConsentBar.message">
            <Link to={`/${language}/cookies-policy`}>Política de Cookies</Link>
          </Trans>
        </p>
        <div className="cookie-consent-buttons">
          <button onClick={handleAcceptAll} className="accept-all-btn">
            {t('cookieConsentBar.accept_all')}
          </button>
          <button onClick={handleAcceptEssential} className="accept-essential-btn">
            {t('cookieConsentBar.accept_essential')}
          </button>
          <button onClick={handleCustomize} className="customize-btn">
            {t('cookieConsentBar.customize')}
          </button>
        </div>
      </div>

      <CustomizeCookiesModal
        show={showCustomizeModal}
        hideCookiesConsentBar={() => {
          setIsVisible(false);
        }}
        handleClose={() => {
          setShowCustomizeModal(false);
        }}
      />
    </>
  );
};

export default CookieConsentBar;