// src/components/modals/LoadingModal.jsx
import React from 'react';
import ReactDOM from 'react-dom';
import { Modal, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import '../../assets/styles/LoadingModal.css';

const LoadingModal = ({ show }) => {
  const { t } = useTranslation();

  return ReactDOM.createPortal(
    <Modal show={show} backdrop="static" keyboard={false} centered>
      <Modal.Body className="text-center modal-loading-content">
        <Spinner animation="border" className="custom-spinner" />
        <div className="mt-3">{t('loadingModal.loading_wait_message')}</div>
      </Modal.Body>
    </Modal>,
    document.getElementById('modal-root')
  );
};

export default LoadingModal;