// src/components/specific/WishList.jsx
import React from 'react';
import { Spinner, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import WishCard from './WishCard';
import LoadingWave from '../common/LoadingWave';
import '../../assets/styles/WishList.css';

const WishList = ({ wishes, onUpdateWish, loading, error, loadingMore, lastPageReached }) => {
  const { t } = useTranslation();

  return (
    <section className="wish-collage-container">
      {loading ? (
          <LoadingWave />
        ) : error ? (
          <Alert variant="danger">
            {t('wishList.error_loading_wishes')}: {error.message}
          </Alert>
        ) : wishes.length > 0 ? (
          <div>
            <div className="wish-collage">
              {wishes.map((wish, index) => (
                <WishCard key={wish.id} wish={wish} onUpdateWish={onUpdateWish} />
              ))}
            </div>
            {loadingMore ? (
              <LoadingWave />
            ) : lastPageReached ? (
              // <p className="end-message">{t('wishList.end_of_list')}</p>
              <p className="end-message">&nbsp;</p>
            ) : null}
          </div>
        ) : (
          <p className="no-wishes-message">{t('wishList.no_wishes_to_show')}.</p>
        )}
    </section>
  );
};

export default WishList;