import React, { useState, useEffect } from 'react';

import { OverlayTrigger, Tooltip, Form, Button, Alert, Spinner } from 'react-bootstrap';
import georeferencesServices from '../../services/georeferencesServices';
import { MdVerified } from 'react-icons/md';
import userService from '../../services/userService';
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/useAuth';
import { FaPen } from 'react-icons/fa';
import Avatar from '../common/Avatar';

const PerfilForm = () => {
  const { t } = useTranslation();

  const { user, refreshUser } = useAuth();
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [avatar, setAvatar] = useState(null);
  const [avatarPreview, setAvatarPreview] = useState(user?.data_user?.avatar_url || '');
  const [name, setName] = useState(user?.name || '');
  const [email] = useState(user?.email || '');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await georeferencesServices.getCountries();
        setCountries(response.data.data);
      } catch (error) {
        setError(t('common.error_loading_countries'));
      }
    };
    fetchCountries();
  }, [t]);

  useEffect(() => {
    if (user && user.data_user) {
      const userCountry = countries.find(country => country.id === user.data_user.country_id);
      if (userCountry) {
        setSelectedCountry(userCountry.name);
      }
    }
  }, [user, countries]);

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setAvatar(file);
      setAvatarPreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccessMessage('');

    try {
      // Update avatar if selected
      if (avatar) {
        const response = await userService.updateAvatar(avatar);
        if (response.status) {
          setSuccessMessage(t('perfilForm.avatar_update_success'));
        } else {
          setError(t('perfilForm.error_avatar_update'));
        }
      }

      // Update profile fields
      if (name !== user?.name) {
        const response = await userService.updateProfile('name', name);
        if (response.status) {
          setSuccessMessage(t('perfilForm.name_update_success'));
        } else {
          setError(t('perfilForm.error_name_update'));
        }
      }

      if (selectedCountry) {
        const country = countries.find(c => c.name === selectedCountry);
        if (country) {
          const response = await userService.updateProfile('country_id', country.id);
          if (response.status) {
            setSuccessMessage(t('perfilForm.country_update_success'));
          } else {
            setError(t('perfilForm.error_country_update'));
          }
        }
      }

      refreshUser();
      
    } catch (error) {
      setError(t('perfilForm.error_updating_profile'));
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <Form onSubmit={handleSubmit}>
      {/* Avatar - Vista Previa y Cambio */}
      <Form.Group className="mt-3" controlId="avatar" style={{ position: 'relative', textAlign: 'center' }}>
        <div className="avatar-container">
          {avatarPreview ? (
            <Avatar
              url={avatarPreview}
              alt={user.name}
              className="avatar-preview roundedCircle"
              size={150}
            />
          ) : (
            <div className="avatar-placeholder">
              <i className="bi bi-person-circle" style={{ fontSize: '80px', color: '#ddd' }}></i>
            </div>
          )}
          <input
            type="file"
            accept="image/*"
            onChange={handleAvatarChange}
            id="avatar-upload"
            style={{ display: 'none' }}
          />
          <label htmlFor="avatar-upload" className="avatar-upload-icon" style={{
            position: 'absolute',
            bottom: '5px',
            backgroundColor: '#68b0ab',
            borderRadius: '50%',
            padding: '10px',
            cursor: 'pointer',
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)'
          }}>
            <FaPen style={{ fontSize: '24px', color: '#fff' }} />
          </label>
        </div>
      </Form.Group>

      {/* Nombre Completo */}
      <Form.Group className="mt-3" controlId="name">
        <Form.Label>{t('perfilForm.name')}</Form.Label>
        <Form.Control
          type="text"
          placeholder={t('perfilForm.name_placeholder')}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Form.Group>

      {/* Email */}
      <Form.Group className="mt-3" controlId="email">
        <Form.Label>{t('perfilForm.email')}</Form.Label>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip>
              {user?.email_verified_at
                ? t('perfilForm.email_verified')
                : t('perfilForm.email_not_verified')}
            </Tooltip>
          }
        >
          <span style={{ marginLeft: '8px', cursor: 'pointer' }}>
            {user?.email_verified_at ? (
              <MdVerified style={{ color: 'green' }} />
            ) : (
              <MdVerified style={{ color: 'grey' }} />
            )}
          </span>
        </OverlayTrigger>
        <Form.Control
          type="text"
          placeholder={t('perfilForm.email_placeholder')}
          value={email}
          // onChange={(e) => setName(e.target.value)}
          readOnly
        />
      </Form.Group>

      {/* País de Ubicación */}
      <Form.Group className="mt-3" controlId="country">
        <Form.Label>{t('perfilForm.country')}</Form.Label>
        <Form.Control
          as="select"
          value={selectedCountry}
          onChange={handleCountryChange}
        >
          <option value="">{t('perfilForm.select_country')}</option>
          {countries.map((country) => (
            <option key={country.id} value={country.name}>{country.display_name}</option>
          ))}
        </Form.Control>
      </Form.Group>

      {/* Mostrar errores o éxito */}
      {error && <Alert variant="danger">{error}</Alert>}
      {successMessage && <Alert variant="success">{successMessage}</Alert>}

      {/* Botón de Guardar Cambios */}
      <div className="form-actions mt-4 text-center">
        <Button variant="primary" type="submit" disabled={loading}>
          {loading ? <Spinner animation="border" size="sm" /> : t('common.save')}
        </Button>
      </div>
    </Form>
  );
};

export default PerfilForm;
