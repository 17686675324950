import React from "react";
import '../../assets/styles/HashtagText.css';

const HashtagText = ({ text }) => {
  const processText = (text) => {
    const hashtagRegex = /#\w+/g; // Asegura que solo coincida con hashtags válidos
    return text.split(/(\s+)/).map((part, index) => {
      if (hashtagRegex.test(part)) {
        return (
          <span key={index} className="HashtagText-highlight-hashtag">
            {part}
          </span>
        );
      }
      return part;
    });
  };

  return <p>{processText(text)}</p>;
};

export default HashtagText;