// src/components/layout/Header.jsx
import React, { useEffect, useState } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import wishService from '../../services/wishService';
import { useTranslation } from 'react-i18next';
import '../../assets/styles/Header.css';

const Header = () => {
  const { t } = useTranslation();

  const [publishedCount, setPublishedCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const initialSearchQuery = searchParams.get('q') || '';
  const [searchQuery, setSearchQuery] = useState(initialSearchQuery);

  // Actualizar searchQuery cuando searchParams cambien
  useEffect(() => {
    const query = searchParams.get('q') || '';
    setSearchQuery(query);
  }, [searchParams]);

  // Manejar cambios en el campo de búsqueda
  const handleSearchInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  };

  // Manejar la búsqueda cuando el usuario presiona Enter
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      performSearch();
    }
  };

  // Manejar la búsqueda al hacer clic en el icono de la lupa
  const handleSearchIconClick = () => {
    performSearch();
  };

  // Función para realizar la búsqueda
  const performSearch = () => {
    setSearchParams(searchQuery ? { q: searchQuery } : {});
  };

  // Obtener el conteo de deseos publicados
  useEffect(() => {
    const fetchWishCount = async () => {
      try {
        const response = await wishService.getWishesCount();
        if (response.data.status) {
          setPublishedCount(response.data.data.published_count);
        } else {
          console.error("Error al obtener el conteo de deseos:", response.data.message);
        }
      } catch (error) {
        console.error("Error al obtener el conteo de deseos:", error);
      }
    };

    fetchWishCount();
  }, []);

  const handleClearSearch = () => {
    setSearchQuery('');
    setSearchParams({});
  };

  return (
    <header className="header">
      <div className="header-left d-flex align-items-center">
        <Link to="/" className="h1 tmw-title"><h1>TheMillionWall</h1></Link>
        <div className="search-input ms-3">
          <input
            type="text"
            id="searchInput"
            placeholder={t('common.search_placeholder')}
            value={searchQuery}
            onChange={handleSearchInputChange}
            onKeyPress={handleKeyPress}
          />
          {searchQuery && (
            <i className="bi bi-x-circle" onClick={handleClearSearch}></i>
          )}
          <i className="bi bi-search" onClick={handleSearchIconClick}></i>
        </div>
      </div>
      <div className="header-right d-flex align-items-center">
        <div>
          <b><span id="wishCount">{publishedCount.toLocaleString()}</span></b> / 1,000,000 {t('header.wishes_published')}
        </div>
      </div>
    </header>
  );
};

export default Header;