import React, { useState, useCallback, useEffect } from 'react';
import { Tooltip, OverlayTrigger, Tabs, Tab } from 'react-bootstrap';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import AuthPromptModal from '../modals/AuthPromptModal';
import wishService from '../../services/wishService';
import RegisterModal from '../modals/RegisterModal';
import LoadingWave from '../common/LoadingWave';
import { useTranslation } from 'react-i18next';
import { formatDistanceToNow } from 'date-fns';
import LoginModal from '../modals/LoginModal';
import useAuth from '../../hooks/useAuth';
import { Spinner } from 'react-bootstrap';
import Avatar from '../common/Avatar';
import '../../assets/styles/WishDetail.css';

const WishDetail = ({ wish, onUpdateWish, loading }) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  // Inicializamos estados independientemente del loading
  const [userReactions, setUserReactions] = useState([]);
  const [showReactionsFeed, setShowReactionsFeed] = useState(false);
  const [selectedReactionType, setSelectedReactionType] = useState('all');
  // const [pendingReaction, setPendingReaction] = useState(null);
  const [showAuthPromptModal, setShowAuthPromptModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  // const [error, setError] = useState('');
  const reactionTypes = ['like', 'heart', 'dislike'];

  // Función para alternar la vista del feed de reacciones
  const toggleReactionsFeed = () => {
    setShowReactionsFeed(!showReactionsFeed);
  };

  const handleReaction = useCallback(
    async (reactionType) => {
      if (!user) {
        // setPendingReaction(reactionType);
        setShowAuthPromptModal(true);
        return;
      }

      try {
        const response = await wishService.reactToWish(wish.id, {
          reaction_type: reactionType,
          device_id: navigator.userAgent,
        });

        if (response.data.status) {
          const newVote = response.data.data.vote;

          const isRemoved = response.data.data?.removed;

          const updatedWish = {
            ...wish,
            likes: reactionType === 'like' ? wish.likes + (isRemoved ? -1 : 1) : wish.likes,
            hearts: reactionType === 'heart' ? wish.hearts + (isRemoved ? -1 : 1) : wish.hearts,
            dislikes: reactionType === 'dislike' ? wish.dislikes + (isRemoved ? -1 : 1) : wish.dislikes,
            votes: isRemoved
              ? wish.votes.filter(
                  (vote) =>
                    !(vote.user_id === user.id && vote.reaction_type === reactionType)
                )
              : [...wish.votes, newVote],
          };

          setUserReactions(
            isRemoved
              ? userReactions.filter((reaction) => reaction !== reactionType)
              : [...userReactions, reactionType]
          );

          onUpdateWish(updatedWish);
        }
      } catch (error) {
        console.error('Error reacting to wish:', error);
      }
    },
    [wish, user, onUpdateWish, userReactions]
  );

  const renderReactionsTooltip = (reactionType) => (
    <Tooltip id={`tooltip-${reactionType}`}>
      {wish.votes
        .filter((vote) => vote.reaction_type === reactionType)
        .map((vote) => (
          vote.user ? (
            <div key={vote.user_id}>
              {vote.user.name} -{' '}
              {formatDistanceToNow(new Date(vote.created_at), { addSuffix: true })}
            </div>
          ) : (
            <div key={vote.user_id}>{t('wishDetail.anonymous_user')}</div>
          )
        ))}
    </Tooltip>
  );

  const getReactionIcon = (reactionType) => {
    switch (reactionType) {
      case 'like':
        return <i className="bi bi-hand-thumbs-up like-icon"></i>;
      case 'heart':
        return <i className="bi bi-heart heart-icon"></i>;
      case 'dislike':
        return <i className="bi bi-hand-thumbs-down dislike-icon"></i>;
      default:
        return null;
    }
  };

  const handleLoginClick = () => {
    setShowAuthPromptModal(false);
    setShowLoginModal(true);
  };

  const handleRegisterClick = () => {
    setShowAuthPromptModal(false);
    setShowRegisterModal(true);
  };

  // const applyPendingReaction = useCallback(async () => {
  //   try {
  //     if (pendingReaction) {
  //       await handleReaction(pendingReaction);
  //       setPendingReaction(null);
  //     }
  //   } catch (err) {
  //     setError(t('common.error_applying_reaction'));
  //   }
  // }, [pendingReaction, handleReaction, t]);

  // useEffect(() => {
  //   if (user && pendingReaction) {
  //     applyPendingReaction();
  //   }
  // }, [user, pendingReaction, applyPendingReaction]);


  const filterVotesByReactionType = (reactionType) => {
    if (reactionType === 'all') {
      return wish.votes;
    }
    return wish.votes.filter((vote) => vote.reaction_type === reactionType);
  };


  useEffect(() => {
    setUserReactions(
      wish?.votes?.filter((vote) => vote.user_id === user?.id).map((vote) => vote.reaction_type) || []
    );
  }, [wish, user]);

  // Evitar renderizado condicional del contenido principal
  if (loading) {
    return (
      <div className="wish-detail-loading">
        <LoadingWave />
      </div>
    );
  }

  return (
    <div className="">

      {/*{error && <div className="error-message">{error}</div>}*/}

      <div className="wish-header">
        {/*<img
          src={
            wish.user.data_user.avatar_url ||
            'https://via.placeholder.com/50?text=No+Avatar'
          }
          alt={wish.user.name}
          className="avatar"
        />*/}
        <Avatar
          url={wish.user.data_user.avatar_url}
          alt={wish.user.name}
          className="reaction-avatar"
        />
        <div className="wish-detail-user-info">
          <span className="wish-detail-user-name">{wish.user.name}</span>
          <div className="user-meta">
            <img
              className="flag"
              src={`https://flagcdn.com/${wish.user.data_user.country.country_code.toLowerCase()}.svg`}
              alt={wish.user.data_user.country.name}
            />
            <span className="post-time">
              {formatDistanceToNow(new Date(wish.created_at), { addSuffix: true })}
            </span>
          </div>
        </div>
      </div>

      <div className="wish-detail-content">
        <p>{wish.content}</p>
      </div>

      <div className="wish-reactions">
        <OverlayTrigger
          placement="top"
          overlay={renderReactionsTooltip('like')}
          delay={{ show: 250, hide: 400 }}
        >
          <button
            className={`reaction-btn like-btn ${
              userReactions.includes('like') ? 'active' : ''
            }`}
            onClick={() => handleReaction('like')}
          >
            <i
              className={`bi ${
                userReactions.includes('like') ? 'bi-hand-thumbs-up-fill' : 'bi-hand-thumbs-up'
              }`}
            ></i>{' '}
            {wish.likes}
          </button>
        </OverlayTrigger>

        <OverlayTrigger
          placement="top"
          overlay={renderReactionsTooltip('heart')}
          delay={{ show: 250, hide: 400 }}
        >
          <button
            className={`reaction-btn heart-btn ${
              userReactions.includes('heart') ? 'active' : ''
            }`}
            onClick={() => handleReaction('heart')}
          >
            <i
              className={`bi ${userReactions.includes('heart') ? 'bi-heart-fill' : 'bi-heart'}`}
            ></i>{' '}
            {wish.hearts}
          </button>
        </OverlayTrigger>

        <OverlayTrigger
          placement="top"
          overlay={renderReactionsTooltip('dislike')}
          delay={{ show: 250, hide: 400 }}
        >
          <button
            className={`reaction-btn dislike-btn ${
              userReactions.includes('dislike') ? 'active' : ''
            }`}
            onClick={() => handleReaction('dislike')}
          >
            <i
              className={`bi ${
                userReactions.includes('dislike')
                  ? 'bi-hand-thumbs-down-fill'
                  : 'bi-hand-thumbs-down'
              }`}
            ></i>{' '}
            {wish.dislikes}
          </button>
        </OverlayTrigger>
      </div>

      <div className="wish-reactions-feed-toggle" onClick={toggleReactionsFeed}>
        {showReactionsFeed ? (
          <>
            <FaChevronUp /> {t('wishDetail.hide_reactions')}
          </>
        ) : (
          <>
            <FaChevronDown /> {t('wishDetail.show_reactions')}
          </>
        )}
      </div>

      {/*{showReactionsFeed && (
        wish.votes.map((vote) => (
          <div key={`${vote.user_id}-${vote.created_at}`} className="reaction-item">
            {vote.user ? (
              <>
                <Avatar
                  url={vote.user.data_user.avatar_url}
                  alt={vote.user.name}
                />
                <div className="reaction-info">
                  <span className="reaction-user-name">
                    {vote.user.name}{' '}
                    <img
                      src={`https://flagcdn.com/${vote.user.data_user.country.country_code.toLowerCase()}.svg`}
                      alt={vote.user.data_user.country.name}
                      className="reaction-country-flag"
                      style={{ width: '20px', height: '15px', marginLeft: '5px' }}
                    />
                  </span>
                  <span className="reaction-type">
                    {getReactionIcon(vote.reaction_type)} {t(`reactions.${vote.reaction_type}`)}
                  </span>
                  <span className="reaction-time">
                    {formatDistanceToNow(new Date(vote.created_at), { addSuffix: true })}
                  </span>
                </div>
              </>
            ) : (
              <div className="reaction-info">
                <span className="reaction-user-name">{t('wishDetail.anonymous_user')}</span>
                <span className="reaction-time">
                  {formatDistanceToNow(new Date(vote.created_at), { addSuffix: true })}
                </span>
              </div>
            )}
          </div>
        ))
      )}*/}
      {showReactionsFeed && (
        <Tabs
          defaultActiveKey="all"
          id="reaction-tabs"
          className="reaction-tabs"
          onSelect={(k) => setSelectedReactionType(k)}
        >
          <Tab eventKey="all" title={`${t('wishDetail.all_reactions')} (${wish.votes.length})`} >
            {filterVotesByReactionType('all').map((vote) => (
              <div key={`${vote.user_id}-${vote.created_at}`} className="reaction-item">
                <Avatar
                  url={vote.user.data_user.avatar_url}
                  alt={vote.user.name}
                />
                <div className="reaction-info">
                  <span className="reaction-user-name">
                    {vote.user?.name}{' '}
                    <img
                      src={`https://flagcdn.com/${vote.user.data_user.country.country_code.toLowerCase()}.svg`}
                      alt={vote.user.data_user.country.name}
                      className="reaction-country-flag"
                      style={{ width: '20px', height: '15px', marginLeft: '5px' }}
                    />
                  </span>
                  <span className="reaction-type">
                    {getReactionIcon(vote.reaction_type)} {t(`reactions.${vote.reaction_type}`)}
                  </span>
                  <span className="reaction-time">
                    {formatDistanceToNow(new Date(vote.created_at), { addSuffix: true })}
                  </span>
                </div>
              </div>
            ))}
          </Tab>
          {reactionTypes.map((type) => (
            <Tab key={type} eventKey={type} title={`${t(`wishDetail.${type}`)} (${wish.votes.filter(vote => vote.reaction_type === type).length})`} >
              {filterVotesByReactionType(type).map((vote) => (
                <div key={`${vote.user_id}-${vote.created_at}`} className="reaction-item">
                  <Avatar
                    url={vote.user.data_user.avatar_url}
                    alt={vote.user.name}
                  />
                  <div className="reaction-info">
                    <span className="reaction-user-name">
                      {vote.user?.name}{' '}
                      <img
                        src={`https://flagcdn.com/${vote.user.data_user.country.country_code.toLowerCase()}.svg`}
                        alt={vote.user.data_user.country.name}
                        className="reaction-country-flag"
                        style={{ width: '20px', height: '15px', marginLeft: '5px' }}
                      />
                    </span>
                    <span className="reaction-type">
                      {getReactionIcon(vote.reaction_type)} {t(`reactions.${vote.reaction_type}`)}
                    </span>
                    <span className="reaction-time">
                      {formatDistanceToNow(new Date(vote.created_at), { addSuffix: true })}
                    </span>
                  </div>
                </div>
              ))}
            </Tab>
          ))}
        </Tabs>
      )}

      {/* Modales */}
      <LoginModal show={showLoginModal} onClose={() => setShowLoginModal(false)} />

      <RegisterModal show={showRegisterModal} onClose={() => setShowRegisterModal(false)} />

      <AuthPromptModal
        show={showAuthPromptModal}
        onClose={() => setShowAuthPromptModal(false)}
        onLogin={handleLoginClick}
        onRegister={handleRegisterClick}
        title={t('wishCard.to_react_to_this_wish_you_need')}
      />

    </div>
  );
};

export default WishDetail;