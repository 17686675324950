import React from 'react';

/**
 * Genera un color basado en un hash del nombre del usuario.
 * @param {string} name - El nombre del usuario.
 * @returns {string} - Un color hexadecimal.
 */
const generateColor = (name) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `#${((hash >> 24) & 0xff).toString(16).padStart(2, '0')}${
    ((hash >> 16) & 0xff).toString(16).padStart(2, '0')
  }${((hash >> 8) & 0xff).toString(16).padStart(2, '0')}`;
  return color.length === 7 ? color : '#888888';
};

/**
 * Genera una matriz simétrica de píxeles basada en un hash del nombre.
 * @param {string} name - El nombre del usuario.
 * @param {number} size - El tamaño de la matriz (por ejemplo, 10 para una matriz 10x10).
 * @returns {number[][]} - Una matriz simétrica.
 */
const generatePixelMatrix = (name, size = 10) => {
  const hash = name.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
  const middle = Math.floor(size / 2);
  const matrix = Array.from({ length: size }, () => Array(size).fill(0));

  for (let row = 0; row < size; row++) {
    for (let col = 0; col <= middle; col++) {
      const isFilled = (hash >> (row * size + col)) % 2; // Determinístico y único
      matrix[row][col] = isFilled;
      matrix[row][size - col - 1] = isFilled; // Simetría
    }
  }

  return matrix;
};

/**
 * Genera un avatar pixelado único basado en un hash del nombre.
 * @param {string} name - El nombre del usuario.
 * @param {number} size - El tamaño del avatar.
 * @returns {string} - Una cadena SVG en formato data URI.
 */
const generateAvatarSvg = (name, size) => {
  const primaryColor = generateColor(name || 'Default');
  const backgroundColor = generateColor(`${name}-background`); // Color único para el fondo
  const matrix = generatePixelMatrix(name);
  const pixelSize = Math.floor(size / matrix.length);
  const svgParts = [];

  svgParts.push(`<rect width="${size}" height="${size}" fill="${backgroundColor}" />`);

  matrix.forEach((row, rowIndex) => {
    row.forEach((pixel, colIndex) => {
      if (pixel === 1) {
        const x = colIndex * pixelSize;
        const y = rowIndex * pixelSize;
        svgParts.push(
          `<rect x="${x}" y="${y}" width="${pixelSize}" height="${pixelSize}" fill="${primaryColor}" />`
        );
      }
    });
  });

  return `data:image/svg+xml;base64,${btoa(`
    <svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 ${size} ${size}">
      ${svgParts.join('')}
    </svg>
  `)}`;
};

const Avatar = ({ url, alt, className, name, size = 50 }) => {
  const userName = name || alt || `Visitor-${Math.random().toString(36).substr(2, 5)}`;
  const imageSrc = url || generateAvatarSvg(userName, size);

  return (
    <img
      src={imageSrc}
      alt={alt || `${userName}'s Avatar`}
      className={className}
      style={{
        width: size,
        height: size,
        borderRadius: '8px',
        objectFit: 'cover',
      }}
    />
  );
};

export default Avatar;