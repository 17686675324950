// src/services/wallet.js
import API from '../api';

const walletService = {
  getWallet: () => API.get('/wallet'),
  getRewards: () => API.get('/rewards'),
  getTransactions: () => API.get('/transactions'),
  postTransactions: (data) => API.post('/transactions', data),
  giftAWish: (data) => API.post('/gift-a-wish', data),
};

export default walletService;