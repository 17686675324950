import axios from 'axios';
import { CONSTANTS } from '../config/constants';

const API = axios.create({
  baseURL: `${CONSTANTS.API_BASE_URL}/v1`,
});

// Interceptor para agregar el token en cada petición
API.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export default API;