// src/components/forms/PreferencesForm.jsx
import React, { useState, useEffect } from 'react';

import { Form, Button, Alert, Spinner } from 'react-bootstrap';
import systemPreferencesService from '../../services/systemPreferencesService';
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/useAuth';

const PreferencesForm = () => {
  const { t } = useTranslation();
  const { refreshUser } = useAuth();
  
  // Estados para las preferencias del sistema
  const [marketingEmails, setMarketingEmails] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const [allowEmailNotifications, setAllowEmailNotifications] = useState(false);
  const [allowSmsNotifications, setAllowSmsNotifications] = useState(false);
  const [timezone, setTimezone] = useState('');
  const [languagePreference, setLanguagePreference] = useState('');
  
  // Estados para manejar la carga, errores y mensajes de éxito
  const [loading, setLoading] = useState(false);
  const [loadingPreferences, setLoadingPreferences] = useState(true);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  
  // Opciones para Timezone y Lenguaje
  const timezones = [
    'Pacific Standard Time',
    'Mountain Standard Time',
    'Central Standard Time',
    'Eastern Standard Time',
    'Central European Standard Time',
    'Greenwich Mean Time',
    'Indian Standard Time',
    'China Standard Time',
    // Agrega más zonas horarias según sea necesario
  ];
  
  const languages = [
    { code: 'EN-us', label: 'Inglés (EE.UU.)' },
    { code: 'ES-es', label: 'Español (España)' },
    { code: 'FR-fr', label: 'Francés (Francia)' },
    { code: 'DE-de', label: 'Alemán (Alemania)' },
    // Agrega más idiomas según sea necesario
  ];

  // Auto-dismiss de errores después de 5 segundos
  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError('');
      }, 5000); // 5 segundos

      return () => clearTimeout(timer);
    }
  }, [error]);

  // Auto-dismiss de mensajes de éxito después de 5 segundos
  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage('');
      }, 5000); // 5 segundos

      return () => clearTimeout(timer);
    }
  }, [successMessage]);
  
  // Obtener las preferencias actuales del usuario al montar el componente
  useEffect(() => {
    const fetchPreferences = async () => {
      try {
        setLoadingPreferences(true);
        const response = await systemPreferencesService.getSystemPreferences();
        if (response.data.status) {
          const prefs = response.data.data.system_preferences;
          setMarketingEmails(prefs.marketing_emails === 1);
          setNewsletter(prefs.newsletter === 1);
          setAllowEmailNotifications(prefs.allow_email_notifications === 1);
          setAllowSmsNotifications(prefs.allow_sms_notifications === 1);
          setTimezone(prefs.timezone || '');
          setLanguagePreference(prefs.language_preference || '');
        } else {
          setError(t('preferencesForm.error_getting_system_preferences'));
        }
      } catch (err) {
        setError(t('preferencesForm.error_getting_system_preferences'));
      } finally {
        setLoadingPreferences(false);
      }
    };
    
    fetchPreferences();
  }, [t]);
  
  // useEffect para deshabilitar y desmarcar las preferencias de marketing cuando no se permiten notificaciones por email
  useEffect(() => {
    if (!allowEmailNotifications) {
      setMarketingEmails(false);
      setNewsletter(false);
    }
  }, [allowEmailNotifications]);
  
  // Manejar el envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccessMessage('');
    
    // Preparar los datos a enviar
    const systemPreferencesData = {
      marketing_emails: marketingEmails,
      newsletter: newsletter,
      allow_email_notifications: allowEmailNotifications,
      allow_sms_notifications: allowSmsNotifications,
      timezone: timezone,
      language_preference: languagePreference
    };
    
    try {
      const response = await systemPreferencesService.postSystemPreferences(systemPreferencesData);
      if (response.data.status) {
        setSuccessMessage(t('preferencesForm.preferences_updated_successfully'));
        refreshUser(); // Refrescar los datos del usuario si es necesario
      } else {
        setError(t('preferencesForm.error_updating_preferences'));
      }
    } catch (err) {
      setError(t('preferencesForm.error_updating_preferences'));
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <Form onSubmit={handleSubmit}>
    
      {/* Notificaciones por Email */}
      <Form.Group className="mt-3">
        <Form.Check 
          type="checkbox" 
          label={t('preferencesForm.allow_email_notifications_label')}
          checked={allowEmailNotifications} 
          onChange={(e) => setAllowEmailNotifications(e.target.checked)} 
          id="allowEmailNotifications"
          autoComplete="on"
        />
      </Form.Group>
      
      {/* Preferencias de Marketing */}
      <Form.Group className="mt-3">
        <Form.Label>{t('preferencesForm.notification_preference_label')}</Form.Label>
        <Form.Check 
          type="checkbox" 
          label={t('preferencesForm.allow_marketing_emails_label')} 
          checked={marketingEmails} 
          onChange={(e) => setMarketingEmails(e.target.checked)} 
          disabled={!allowEmailNotifications}
          id="marketingEmails1" // ID único
          autoComplete="off"
        />
        <Form.Check 
          type="checkbox" 
          label={t('preferencesForm.allow_newsletter_label')} 
          checked={newsletter} 
          onChange={(e) => setNewsletter(e.target.checked)} 
          disabled={!allowEmailNotifications}
          id="marketingEmails2" // ID único
          autoComplete="off"
        />
      </Form.Group>
      
      {/* Notificaciones por SMS */}
      {/*<Form.Group className="mt-3"
        <Form.Check 
          type="checkbox" 
          label="Permitir notificaciones por SMS" 
          checked={allowSmsNotifications} 
          onChange={(e) => setAllowSmsNotifications(e.target.checked)} 
          id="allowSmsNotifications"
          autoComplete="on"
        />
      </Form.Group>*/}
      
      {/* Zona Horaria */}
      <Form.Group className="mt-3">
        <Form.Label>{t('preferencesForm.timezone_label')}</Form.Label>
        <Form.Control 
          as="select" 
          value={timezone} 
          onChange={(e) => setTimezone(e.target.value)} 
          id="timezone"
          autoComplete="timezone"
        >
          <option value="">{t('preferencesForm.select_your_timezone')}</option>
          {timezones.map((tz, index) => (
            <option key={index} value={tz}>{tz}</option>
          ))}
        </Form.Control>
      </Form.Group>
      
      {/* Preferencia de Lenguaje */}
      <Form.Group className="mt-3">
        <Form.Label>{t('preferencesForm.language_preference_label')}</Form.Label>
        <Form.Control 
          as="select" 
          value={languagePreference} 
          onChange={(e) => setLanguagePreference(e.target.value)} 
          id="languagePreference"
          autoComplete="language"
        >
          <option value="">{t('preferencesForm.select_your_language_preferences')}</option>
          {languages.map((lang) => (
            <option key={lang.code} value={lang.code}>{lang.label}</option>
          ))}
        </Form.Control>
      </Form.Group>
      
      {/* Mostrar errores o éxito */}
      {error && (
        <Alert 
          variant="danger" 
          className="mt-3" 
          dismissible 
          onClose={() => setError('')}
          transition
        >
          {error}
        </Alert>
      )}
      {successMessage && (
        <Alert 
          variant="success" 
          className="mt-3" 
          dismissible 
          onClose={() => setSuccessMessage('')}
          transition
        >
          {successMessage}
        </Alert>
      )}
      
      {/* Botón de Guardar Cambios */}
      <div className="form-actions mt-4 text-center">
        <Button variant="primary" type="submit" disabled={loading || loadingPreferences}>
          {loading ? <Spinner animation="border" size="sm" /> : t('common.save')}
        </Button>
      </div>
    </Form>
  );
};

export default PreferencesForm;