import React, { useState, useEffect, useRef } from "react";
import LoadingWave from '../common/LoadingWave';
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import "../../assets/styles/WishEditor.css";

const MAX_CHARACTERS = 500;

const WishEditor = ({ wishText, setWishText, error, handlePublishClick }) => {
  const { t } = useTranslation();
  const [focused, setFocused] = useState(false);
  const textareaRef = useRef(null);
  const previewRef = useRef(null);
  const location = useLocation();

  const handleInputChange = (event) => {
    const value = event.target.value;
    // setWishText(value);
    // adjustHeight();

    if (value.length <= MAX_CHARACTERS) {
      setWishText(value);
      adjustHeight();
    }
  };

  const adjustHeight = () => {
    if (textareaRef.current && previewRef.current) {
      // Reinicia la altura para calcular la altura necesaria
      textareaRef.current.style.height = "auto";
      previewRef.current.style.height = "auto";

      // Ajusta la altura al contenido
      const newHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = `${newHeight}px`;
      previewRef.current.style.height = `${newHeight}px`;
    }
  };

  useEffect(() => {
    adjustHeight();
  }, [wishText]);

  const highlightText = (text) => {
    const hashtagRegex = /#(\w+)/g;
    const mentionRegex = /@(\w+)/g;

    return text.replace(
      hashtagRegex,
      '<span class="wish-editor-highlight hashtag">#$1</span>'
    );
  };


  const isPaymentSuccess =
    location.pathname === "/payment/success" ||
    location.pathname === "/payment/paypal/success";

  return (
    <div className={`wish-editor-container ${focused ? "focused" : ""}`}>
      <div className="wish-editor-wrapper">
        <div
          ref={previewRef}
          className="wish-editor-preview"
          dangerouslySetInnerHTML={{ __html: highlightText(wishText) }}
        />

        <textarea
          ref={textareaRef}
          id="wishTextarea"
          value={wishText}
          onChange={handleInputChange}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          placeholder={t("wishForm.placeholder_write")}
          style={{
            width: "100%",
            border: "none",
            outline: "none",
            resize: "none",
            fontSize: "12px",
            fontFamily: "inherit",
            background: "transparent",
            zIndex: 2,
            position: "relative",
            color: "transparent",
            caretColor: "#000",
          }}
        />

        <div className="wish-editor-counter">
          {MAX_CHARACTERS - wishText.length} {t("wishForm.characters_remaining")}
        </div>

      </div>
      {error && (
        <p className="wish-editor-error">
          {t("wishForm.write_your_wish_before_publish")}
        </p>
      )}
      
      {/* si estamos en la ruta payment/success o payment/paypal/success no mostrar el boton y mostrar el LoadingWave */}
      {isPaymentSuccess ? (
        <LoadingWave />
      ) : (
        <button onClick={handlePublishClick} className="wish-editor-button">
          {t("wishForm.publish_by_1_usd")}
        </button>
      )}

    </div>
  );
};

export default WishEditor;
