// src/components/common/ErrorBoundary.jsx
import React from 'react';
import { Button, Container } from 'react-bootstrap';
import { FaExclamationTriangle } from 'react-icons/fa';
import '../../assets/styles/ErrorBoundary.css';
const language = localStorage.getItem('language') || 'en';

class ErrorBoundary extends React.Component {

  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Actualiza el estado para mostrar la UI de fallback
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Puedes registrar el error en un servicio de reporte de errores
    this.setState({ error, errorInfo });
  }

  handleReload = () => {
    // Recarga la página o reinicia el estado del componente
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      const { error, errorInfo } = this.state;
      return (
        <Container className="error-boundary">
          <div className="error-boundary-content">
            <FaExclamationTriangle className="error-icon" />
            <h1>¡Algo salió mal!</h1>
            <p>
              Lo sentimos, ocurrió un error inesperado. Por favor, intenta
              recargar la página o contáctanos si el problema persiste.
            </p>
            <details className="error-details">
              <summary>Detalles del error</summary>
              <p>{error?.toString()}</p>
              <pre>{errorInfo?.componentStack}</pre>
            </details>
            <div className="error-actions">
              <Button variant="primary" onClick={this.handleReload}>
                Recargar Página
              </Button>
              <Button variant="secondary" onClick={() => window.location.href = `/${language}/contact`}>
                Reportar Problema
              </Button>
            </div>
          </div>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;