// src/components/modals/WithdrawFundsModal.jsx
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button, Form, Alert, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import '../../assets/styles/WithdrawFundsModal.css'; // Importa tus estilos personalizados si los tienes

const WithdrawFundsModal = ({
  show,
  handleClose,
  handleWithdraw,
  withdrawLoading,
  withdrawError,
  withdrawSuccess,
  availableBalance, // Prop para mostrar el saldo disponible
}) => {
  const { t } = useTranslation();
  const [withdrawAmount, setWithdrawAmount] = useState('');

  // Reiniciar el monto y los mensajes al cerrar el modal
  useEffect(() => {
    if (!show) {
      setWithdrawAmount('');
    }
  }, [show]);

  // Manejar el envío del formulario
  const onSubmit = (e) => {
    e.preventDefault();
    handleWithdraw(withdrawAmount);
  };

  return ReactDOM.createPortal(
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
      size="md" // Tamaño mediano, ajusta según tus necesidades
    >
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{t('UserWallet.withdraw_funds')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="withdrawAmount">
            <Form.Label>{t('UserWallet.enter_amount')}</Form.Label>
            <Form.Control
              type="number"
              placeholder={t('UserWallet.enter_amount_placeholder')}
              value={withdrawAmount}
              onChange={(e) => setWithdrawAmount(e.target.value)}
              min="0.01"
              step="0.01"
              required
              disabled={withdrawLoading}
            />
            <Form.Text className="text-muted">
              {t('UserWallet.available_balance')}: {availableBalance} USD
            </Form.Text>
          </Form.Group>
          {/* Mostrar mensajes de error o éxito */}
          {withdrawError && (
            <Alert variant="danger" className="mt-3">
              {withdrawError}
            </Alert>
          )}
          {withdrawSuccess && (
            <Alert variant="success" className="mt-3">
              {withdrawSuccess}
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button className="cancel-custom-btn" onClick={handleClose} disabled={withdrawLoading}>
            {t('common.cancel')}
          </Button>
          <Button type="submit" disabled={withdrawLoading || !withdrawAmount}>
            {withdrawLoading ? <Spinner animation="border" size="sm" /> : t('common.continue')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>,
    document.getElementById('modal-root') // Asegúrate de tener este elemento en tu HTML
  );
};

export default WithdrawFundsModal;