// src/routes/PrivateRoute.jsx
import React from 'react';
import ModalLoading from '../components/modals/ModalLoading'; 
import { Navigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAuth from '../hooks/useAuth';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { t } = useTranslation();
  const { user, loading } = useAuth();
  const location = useLocation(); // Obtener la ruta actual
  const language = localStorage.getItem('language') || 'en';

  if (loading) {
    return <ModalLoading show={true} message={t('common.loading_user_info')} />;
  }

  if (user) {
    return <Component {...rest} />;
  } else {
    return (
      <Navigate
        to={`/${language}/`}
        state={{ from: { pathname: location.pathname, search: location.search } }} 
        replace
      />
    );
  }
};

export default PrivateRoute;