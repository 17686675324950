import React from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import '../../assets/styles/WishConfirmationModal.css';

const WishConfirmationModal = ({ show, wish, onClose, onProceed, confirmationError }) => {
  const { t } = useTranslation();

  return ReactDOM.createPortal(
    <Modal
      show={show}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      centered
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('wishConfirmationModal.comfirm_your_wish_title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t('wishConfirmationModal.comfirm_your_wish_description')}</p>
        <blockquote className="blockquote text-center">
          <p className="mb-0">{wish}</p>
        </blockquote>
        {confirmationError && (
          <Alert variant="danger" className="mt-3">
            {confirmationError}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button className="cancel-custom-btn" onClick={onClose}>
          {t('common.cancel')}
        </Button>
        <Button className="proceed-custom-btn" onClick={onProceed}>
          {t('common.continue')}
        </Button>
      </Modal.Footer>
    </Modal>,
    document.getElementById('modal-root')
  );
};

export default WishConfirmationModal;