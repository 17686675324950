// src/components/specific/TopWishCard.jsx
import React, { useEffect, useState, useCallback } from 'react';
import AuthPromptModal from '../modals/AuthPromptModal';
import wishService from '../../services/wishService';
import RegisterModal from '../modals/RegisterModal';
import MessageModal from '../modals/MessageModal';
import { useTranslation } from 'react-i18next';
import LoginModal from '../modals/LoginModal';
import useAuth from '../../hooks/useAuth';
import HashtagText from "./HashtagText";
import Avatar from '../common/Avatar';
import '../../assets/styles/TopWishCard.css';

const TopWishCard = ({ wish, rank, onUpdateWish }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [showAuthPromptModal, setShowAuthPromptModal] = useState(false);
  // const [pendingReaction, setPendingReaction] = useState(null);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalType, setModalType] = useState('info');
  const [userReactions, setUserReactions] = useState([]);

  // Hook para identificar la reacción específica del usuario autenticado
  useEffect(() => {
    if (user && wish?.votes) {
      const reactions = wish.votes
        .filter((vote) => vote.user_id === user.id)
        .map((vote) => vote.reaction_type);
      setUserReactions(reactions);
    } else {
      setUserReactions([]);
    }
  }, [user, wish]);

  // // Hook para manejar reacciones pendientes
  // useEffect(() => {
  //   const applyPendingReaction = async () => {
  //     if (pendingReaction) {
  //       await handleReaction();
  //       setPendingReaction(null);
  //     }
  //   };

  //   if (user && pendingReaction) {
  //     applyPendingReaction();
  //   }
  // }, [user, pendingReaction]);

  const handleReaction = useCallback(async () => {
    const reactionType = 'like';
    if (!user) {
      // setPendingReaction(reactionType);
      setShowAuthPromptModal(true);
      return;
    }

    try {
      const response = await wishService.reactToWish(wish.id, {
        reaction_type: reactionType,
        device_id: navigator.userAgent,
      });

      if (response.data.status) {
        const isRemoved = response.data.data?.removed;

        const updatedWish = {
          ...wish,
          likes: wish.likes + (isRemoved ? -1 : 1),
          votes: isRemoved
            ? wish.votes.filter(
                (vote) =>
                  !(vote.user_id === user.id && vote.reaction_type === reactionType)
              )
            : [...wish.votes, response.data.data.vote],
        };

        onUpdateWish(updatedWish);
      } else {
        setModalType('warning');
        setModalMessage(t(`errors.${response.data.message}`) || t('errors.something_went_wrong_try_again'));
        setShowMessageModal(true);
      }
    } catch (error) {
      setModalType('error');
      setModalMessage(
        t(`errors.${error.response?.data?.message}`) || t('errors.error_trying_to_react_try_again')
      );
      setShowMessageModal(true);
    }
  }, [user, wish, onUpdateWish, t]);

  const handleLoginClick = () => {
    setShowAuthPromptModal(false);
    setShowLoginModal(true);
  };

  const handleRegisterClick = () => {
    setShowAuthPromptModal(false);
    setShowRegisterModal(true);
  };

  if (!wish) return null;

  return (
    <div className="top-wish">
      <div className="rank-number">{rank}</div>
      <div className="user-info">
        <Avatar
          url={wish.user.data_user.avatar_url}
          alt={wish.user.name}
          className="avatar"
          size={30}
        />
        <span className="username">{wish.user.name}</span>
        <img
          className="flag"
          src={`https://flagcdn.com/${wish.user.data_user.country.country_code.toLowerCase()}.svg`}
          alt={wish.user?.data_user?.country?.name || t('topWishCard.country_flag')}
        />
      </div>

      <HashtagText text={wish.content} />
      
      <div className="wish-meta">
        <button
          className={`likes-btn ${userReactions.includes('like') ? 'active' : ''}`}
          onClick={handleReaction}
        >
          <i className={`bi ${userReactions.includes('like') ? 'bi-hand-thumbs-up-fill' : 'bi-hand-thumbs-up'}`}></i> {wish.likes}
        </button>
        <span className="time">{wish.time}</span>
      </div>

      {/* Modales */}
      <LoginModal show={showLoginModal} onClose={() => setShowLoginModal(false)} />
      <RegisterModal show={showRegisterModal} onClose={() => setShowRegisterModal(false)} />
      <AuthPromptModal
        show={showAuthPromptModal}
        onClose={() => setShowAuthPromptModal(false)}
        onLogin={handleLoginClick}
        onRegister={handleRegisterClick}
        title={t('topWishCard.to_react_to_this_wish_you_need')}
      />
      <MessageModal
        show={showMessageModal}
        type={modalType}
        message={modalMessage}
        onClose={() => setShowMessageModal(false)}
      />
    </div>
  );
};

export default TopWishCard;