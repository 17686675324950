// src/routes/index.jsx
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';

// Importa tus páginas
import HomePage from '../pages/HomePage';
import WishPage from '../pages/WishPage';

import PrivacyPolicy from '../pages/PrivacyPolicy';
import CookiesPolicy from '../pages/CookiesPolicy';
import TermsAndConditions from '../pages/TermsAndConditions';
import AboutUs from '../pages/AboutUs';
import HowItWorks from '../pages/HowItWorks';
import Contact from '../pages/Contact';
import PrizeAndRaffleBase from '../pages/PrizeAndRaffleBase';

import PrivateRoute from './PrivateRoute';
import PaymentSuccess from '../pages/PaymentSuccess';
import PaymentCancel from '../pages/PaymentCancel';
import PaymentPaypalSuccess from '../pages/PaymentPaypalSuccess';

import DevAuth from '../pages/DevAuth';

import Config from '../pages/Config';

const supportedLanguages = ['en', 'es'];
const defaultLanguage = localStorage.getItem('language') || 'en';


const LanguageRedirect = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(Boolean);

  if (pathSegments.length > 0 && supportedLanguages.includes(pathSegments[0])) {
    return <Navigate to={`${location.pathname}${location.search}`} replace />;
  }

  return <Navigate to={`/${defaultLanguage}${location.pathname}${location.search}`} replace />;
};


// Función para verificar si el usuario está autorizado en modo desarrollo
const isDevAuthorized = () => {
  const env = process.env.REACT_APP_ENV;
  if (env !== 'development' && env !== 'staging') {
    return true; // Solo aplica para entornos development y staging
  }

  // Obtener la lista de correos autorizados y normalizarla
  const authorizedEmails = process.env.REACT_APP_AUTHORIZE_TESTING_EMAILS
    ? process.env.REACT_APP_AUTHORIZE_TESTING_EMAILS.split(',').map(email => email.trim().toLowerCase())
    : [];

  // Obtener el correo almacenado en localStorage
  const devAuthEmail = localStorage.getItem('devAuthEmail');

  // Verificar si el correo existe y está autorizado
  return devAuthEmail && authorizedEmails.includes(devAuthEmail.trim().toLowerCase());
};


const DevProtectedRoute = ({ element: Element }) => {
  return isDevAuthorized() ? <ValidateLocalizaton element={Element} /> : <Navigate to="/dev-auth" />;
};


const ValidateLocalizaton = ({ element: Element }) => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(Boolean);

  if (pathSegments.length > 0 && supportedLanguages.includes(pathSegments[0])) {
    return <Element />;
  } else {
    return LanguageRedirect();
  }

  return <Element />;
};

const AppRoutes = () => {

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const referralCode = params.get('referral_code');
    if (referralCode) {
      localStorage.setItem('referral_code', referralCode);
      console.log(`Referral code stored: ${referralCode}`);
      // window.history.replaceState({}, document.title, location.pathname);
    }
  }, [location]);


  return (
    <Routes>
      {/* Ruta de autenticación de desarrollo */}
      <Route path="/dev-auth" element={<DevAuth />} />

      {/* Ruta inicial */}
      <Route path="/" element={<Navigate to={`/${defaultLanguage}`} />} />

      {/* Rutas con idioma */}
      <Route path="/:lang">
        <Route index element={<DevProtectedRoute element={HomePage} />} />
        <Route path="login" element={<DevProtectedRoute element={HomePage} />} />
        <Route path="register" element={<DevProtectedRoute element={HomePage} />} />
        <Route path="email-verification-process" element={<DevProtectedRoute element={HomePage} />} />
        <Route path="privacy-policy" element={<DevProtectedRoute element={PrivacyPolicy} />} />
        <Route path="cookies-policy" element={<DevProtectedRoute element={CookiesPolicy} />} />
        <Route path="terms-and-conditions" element={<DevProtectedRoute element={TermsAndConditions} />} />
        <Route path="about-us" element={<DevProtectedRoute element={AboutUs} />} />
        <Route path="how-it-works" element={<DevProtectedRoute element={HowItWorks} />} />
        <Route path="contact" element={<DevProtectedRoute element={Contact} />} />
        <Route path="prize-and-raffle-base" element={<DevProtectedRoute element={PrizeAndRaffleBase} />} />
        <Route path="wish/:id" element={<DevProtectedRoute element={WishPage} />} />

        <Route path="payment/success" element={<DevProtectedRoute element={PaymentSuccess} />} />
        <Route path="payment/cancel" element={<DevProtectedRoute element={PaymentCancel} />} />
        <Route path="payment/paypal/success" element={<DevProtectedRoute element={PaymentPaypalSuccess} />} />

        {/* Rutas privadas dentro del idioma */}
        <Route path="config" element={<PrivateRoute component={Config} />} />
       {/* <Route path="payment/success" element={<PrivateRoute component={PaymentSuccess} />} />
        <Route path="payment/cancel" element={<PrivateRoute component={PaymentCancel} />} />
        <Route path="payment/paypal/success" element={<PrivateRoute component={PaymentPaypalSuccess} />} />*/}
      </Route>

      {/* Rutas sin idioma van al final */}
      {/*<Route path="/payment/success" element={<PrivateRoute component={PaymentSuccess} />} />
      <Route path="/payment/cancel" element={<PrivateRoute component={PaymentCancel} />} />
      <Route path="/payment/paypal/success" element={<PrivateRoute component={PaymentPaypalSuccess} />} />*/}
      <Route path="/payment/success" element={<DevProtectedRoute element={PaymentSuccess} />} />
      <Route path="/payment/cancel" element={<DevProtectedRoute element={PaymentCancel} />} />
      <Route path="/payment/paypal/success" element={<DevProtectedRoute element={PaymentPaypalSuccess} />} />

      {/* Cualquier otra ruta */}
      <Route path="*" element={<LanguageRedirect />} />
    </Routes>
  );
};

export default AppRoutes;