// src/config/constants.js
const ENV = process.env.REACT_APP_ENV || 'development';
const API_BASE_URL = process.env.REACT_APP_THEMILLIONWALL_API_BASE_URL || 'https://127.0.0.1/api/';
const WS_BASE_URL = process.env.REACT_APP_THEMILLIONWALL_WS_BASE_URL || 'wss://127.0.0.1:8080';
const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '';

export const CONSTANTS = {
  ENV,
  API_BASE_URL,
  WS_BASE_URL,
  STRIPE_PUBLISHABLE_KEY,
};