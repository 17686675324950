// src/components/common/Footer.jsx
import React, { useState } from 'react';
import LanguageSelectorModal from '../modals/LanguageSelectorModal';
import CustomizeCookiesModal from '../modals/CustomizeCookiesModal';
import { FaGlobe, FaCookieBite } from 'react-icons/fa';
// import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../../assets/styles/Footer.css';

const Footer = () => {
  const { t } = useTranslation();

  const [isModalOpen, setModalOpen] = useState(false);
  const language = localStorage.getItem('language') || 'en';
  const [showCustomizeModal, setShowCustomizeModal] = useState(false);
  // const navigate = useNavigate();

  // const handleLanguageChange = (lang) => {
  //   localStorage.setItem('language', lang);
  //   const supportedLanguages = ['en', 'es'];
  //   const pathSegments = window.location.pathname.split('/');
  //   let currentPathSegments;

  //   if (supportedLanguages.includes(pathSegments[1])) {
  //     currentPathSegments = pathSegments.slice(2);
  //   } else {
  //     currentPathSegments = pathSegments.slice(1);
  //   }

  //   const currentPath = currentPathSegments.join('/');
  //   const queryString = window.location.search;
  //   setModalOpen(false);
  //   navigate(`/${lang}/${currentPath}${queryString}`);
  // };
  const handleLanguageChange = (lang) => {
    localStorage.setItem('language', lang);
    const currentPath = window.location.pathname.split('/').slice(2).join('/');
    const queryString = window.location.search;
    window.location.href = `/${lang}/${currentPath}${queryString}`;
  };

  return (
    <>
      <footer className="footer">
        <div className="footer-content">

          {/* Contenedor principal de enlaces y botones */}
          <div className="footer-main">
          
            {/* Enlaces del footer */}
            <div className="footer-links">
              <Link to={`/${language}/privacy-policy`}>{t('footer.privacy_policy')}</Link>

              <span className="footer-separator-dash">-</span>
              
              <Link to={`/${language}/cookies-policy`}>{t('footer.cookies_politcy')}</Link>

              <span className="footer-separator-dash">-</span>
              
              <Link to={`/${language}/terms-and-conditions`}>{t('footer.terms_of_use')}</Link>

              <span className="footer-separator-dash">-</span>
              
              <Link to={`/${language}/about-us`}>{t('footer.about_us')}</Link>

              <span className="footer-separator-dash">-</span>
              
              <Link to={`/${language}/how-it-works`}>{t('footer.how_it_works')}</Link>

              <span className="footer-separator-dash">-</span>
              
              <Link to={`/${language}/prize-and-raffle-base`}>{t('footer.prize_and_raffle_base')}</Link>

              <span className="footer-separator-dash">-</span>
              
              <Link to={`/${language}/contact`}>{t('footer.contact_us')}</Link>

            </div>
            
            {/* Botón de idioma y copyright en escritorio */}
            <div className="footer-right">
              <button onClick={() => setShowCustomizeModal(true)} className="language-change-btn">
                <FaCookieBite className="language-icon" /> {t('common.cookies')}
              </button>

              <span className="footer-separator">|</span>

              <button onClick={() => setModalOpen(true)} className="language-change-btn">
                <FaGlobe className="language-icon" /> {language.toUpperCase()}
              </button>

              <span className="footer-separator">|</span>

              <div className="footer-info">
                {t('footer.copyright')}
              </div>
            </div>

          </div>

          {/* Copyright móvil */}
          <div className="footer-info footer-mobile-only">
              <button onClick={() => setShowCustomizeModal(true)} className="language-change-btn">
                <FaCookieBite className="language-icon" /> {t('common.cookies')}
              </button>

              <span className="footer-separator-mobile">|</span>

              <button onClick={() => setModalOpen(true)} className="language-change-btn">
                <FaGlobe className="language-icon" /> {language.toUpperCase()}
              </button>

              <span className="footer-separator-mobile">|</span>

              {t('footer.copyright')}
          </div>

        </div>
      </footer>

      {/* Botón flotante para cambio de idioma en móviles */}
      {/*<button
        className="language-float-btn"
        onClick={() => setModalOpen(true)}
      >
        <FaGlobe />
      </button>*/}

      <LanguageSelectorModal
        show={isModalOpen}
        onClose={() => setModalOpen(false)}
        onSelectLanguage={handleLanguageChange}
      />

      <CustomizeCookiesModal
        show={showCustomizeModal}
        hideCookiesConsentBar={() => {}}
        handleClose={() => {
          setShowCustomizeModal(false);
        }}
      />
    </>
  );
};

export default Footer;