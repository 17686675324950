import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button, Form, Alert, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import '../../assets/styles/GiftWishModal.css';

const GiftWishModal = ({
  show,
  handleClose,
  handleGift,
  giftLoading,
  giftError,
  setGiftError,
  giftSuccess,
  wishGifted
}) => {
  const { t } = useTranslation();
  const [receiverEmail, setReceiverEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false); // Nuevo estado para el modal de confirmación

  // Reiniciar el estado al cerrar el modal
  useEffect(() => {
    if (!show) {
      setReceiverEmail('');
      setConfirmEmail('');
      setShowConfirmation(false); // Reiniciar el estado de confirmación
    }
  }, [show]);

  // Manejar el envío del formulario
  const onSubmit = (e) => {
    e.preventDefault();
    if (receiverEmail !== confirmEmail) {
      setGiftError(t('UserWallet.email_mismatch'));
    } else {
      setShowConfirmation(true); // Mostrar el modal de confirmación
    }
  };

  // Confirmar el regalo
  const confirmGift = () => {
    setShowConfirmation(false); // Cerrar el modal de confirmación
    handleGift(receiverEmail); // Llamar a la función de regalo
  };

  return ReactDOM.createPortal(
    <>
      {/* Modal principal */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="md"
      >
        <Form onSubmit={onSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>{t('UserWallet.gift_a_wish_title')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="receiverEmail">
              <Form.Label>{t('UserWallet.receiver_email')}</Form.Label>
              <Form.Control
                type="email"
                placeholder={t('UserWallet.enter_receiver_email')}
                value={receiverEmail}
                onChange={(e) => setReceiverEmail(e.target.value)}
                required
                disabled={giftLoading}
              />
            </Form.Group>
            <Form.Group controlId="confirmEmail" className="mt-3">
              <Form.Label>{t('UserWallet.confirm_receiver_email')}</Form.Label>
              <Form.Control
                type="email"
                placeholder={t('UserWallet.confirm_receiver_email_placeholder')}
                value={confirmEmail}
                onChange={(e) => setConfirmEmail(e.target.value)}
                required
                disabled={giftLoading}
              />
            </Form.Group>
            {/* Mostrar mensajes de error o éxito */}
            {giftError && (
              <Alert variant="danger" className="mt-3">
                {giftError}
              </Alert>
            )}
            {giftSuccess && (
              <Alert variant="success" className="mt-3">
                {giftSuccess}
              </Alert>
            )}
          </Modal.Body>
          <Modal.Footer>

            <Button variant="secondary" onClick={handleClose} disabled={giftLoading}>
              { (!wishGifted) ? t('common.cancel') : t('common.close') }
            </Button>


            {!wishGifted && (
              <Button type="submit" disabled={giftLoading || !receiverEmail || !confirmEmail}>
                {giftLoading ? <Spinner animation="border" size="sm" /> : t('UserWallet.gift_now')}
              </Button>
            )}
            


          </Modal.Footer>
        </Form>
      </Modal>

      {/* Modal de confirmación */}
      <Modal
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('UserWallet.confirm_gift_title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('UserWallet.confirm_gift_message')}</p>
          <Alert variant="warning">
            {t('UserWallet.gift_irreversible_warning')}
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmation(false)}>
            {t('common.cancel')}
          </Button>
          <Button variant="primary" onClick={confirmGift}>
            {t('UserWallet.confirm_and_continue')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>,
    document.getElementById('modal-root')
  );
};

export default GiftWishModal;