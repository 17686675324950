// src/pages/PaymentCancel.jsx
import React, { useEffect, useState } from 'react';
import TopWishesMobileButton from '../components/specific/TopWishesMobileButton';
import miscellaneousService from '../services/miscellaneousService';
import MainLayout from '../components/layout/MainLayout';
import TopWishes from '../components/specific/TopWishes';
import WishForm from '../components/specific/WishForm';
import { useTranslation, Trans } from 'react-i18next';
import useWindowWidth from '../hooks/useWindowWidth';
import wishService from '../services/wishService';
import { Spinner, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SEO from '../components/common/SEO';
import DOMPurify from 'dompurify';
import '../assets/styles/StaticPageContent.css';

const PaymentCancel = () => {
  const { t } = useTranslation();
  const [wishes, setWishes] = useState([]);
  const [loadingWishes, setLoadingWishes] = useState(true);
  const [errorWishes, setErrorWishes] = useState(null);
  const language = localStorage.getItem('language') || 'en';
  const navigate = useNavigate();
  const width = useWindowWidth();
  const isMobile = width < 768;

  useEffect(() => {
    // Función para obtener todos los deseos
    const fetchAllWishes = async () => {
      try {
        setLoadingWishes(true);
        const response = await wishService.getAllWishes();
        if (response.data.status) {
          setWishes(response.data.data.wishes.data);
        } else {
          setErrorWishes(new Error(t('common.error_loading_wishes')));
        }
      } catch (err) {
        setErrorWishes(err);
      } finally {
        setLoadingWishes(false);
      }
    };

    fetchAllWishes();
  }, []);


  // Función para actualizar un deseo (por ejemplo, likes, hearts)
  const updateWish = (updatedWish) => {
    setWishes((prevWishes) =>
      prevWishes.map((wish) =>
        wish.wishNumber === updatedWish.wishNumber ? updatedWish : wish
      )
    );
  };

  return (
    <MainLayout>

      <SEO 
        titleKey="seo.payment_cancel.title"
        descriptionKey="seo.payment_cancel.description"
        keywordsKey="seo.payment_cancel.keywords"
        lang={language}
        url={`${window.location.origin}/${language}/payment/cancel`}
        image={`${window.location.origin}/images/payment-cancel-banner.jpg`}
        siteTwitter="@andres_elias"
        authorTwitter="@andres_elias"
        localeAlternates={['en_US', 'es_ES']}
        structuredData={{
          '@context': 'https://schema.org',
          '@type': 'WebPage',
          name: t('seo.payment_cancel.title'),
          description: t('seo.payment_cancel.description'),
          url: `${window.location.origin}/${language}/payment/cancel`,
          potentialAction: {
            '@type': 'ViewAction',
            target: `${window.location.origin}/${language}/`,
            name: t('seo.common.back_to_homepage'),
          },
        }}
      />

      <div className="main-container">
        {/* Left Sidebar */}
        <aside className="left-sidebar hide-on-mobile">
          <WishForm />
        </aside>

        {/* Center Content (Payment Cancel Message) */}
        <section className="static-page-content">
          <h2>{t('paymentCancel.payment_cancelled_title')}</h2>
          <p>{t('paymentCancel.cancelled.message1')}</p>
          <p>
            <Trans i18nKey="paymentCancel.cancelled.message2">
              Puedes completar el pago cuando lo creas conveniente para publicar tu deseo y participar por un premio de hasta <strong>$10,000</strong> dólares libres de impuestos.
            </Trans>
          </p>
          <p>{t('paymentCancel.cancelled.message3')}</p>
          <button onClick={() => navigate('/')} className="continue-btn">
            {t('common.back_to_homepage')}
          </button>
        </section>

        {/* Right Sidebar */}
        <aside className="right-sidebar">
          {!isMobile && (
            <TopWishes
              wishes={wishes} 
              onUpdateWish={updateWish}
              loading={loadingWishes}
              error={errorWishes}
            />
          )}

          {isMobile && (
            <TopWishesMobileButton
              wishes={wishes}
              onUpdateWish={updateWish}
              loading={loadingWishes}
              error={errorWishes}
            />
          )}
        </aside>
      </div>
    </MainLayout>
  );
};

export default PaymentCancel;