// src/services/userService.js
import API from '../api';

const userService = {
  // Actualiza el avatar del usuario
  updateAvatar: async (file) => {
    const formData = new FormData();
    formData.append('avatar', file);

    try {
      const res = await API.post('/user/update-avatar', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  // Actualiza un campo específico del perfil del usuario
  updateProfile: async (field, value) => {
    const data = {
      field,
      value,
    };

    try {
      const res = await API.post('/user/update-profile', data);
      return res.data;
    } catch (error) {
      throw error;
    }
  },
};

export default userService;