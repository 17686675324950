// src/components/modals/LanguageSelectorModal.jsx
import React from 'react';
import '../../assets/styles/LanguageSelectorModal.css';
import { useTranslation } from 'react-i18next';

const LanguageSelectorModal = ({ show, onClose, onSelectLanguage }) => {
  const { t, i18n } = useTranslation(); // Mueve el hook al principio del componente

  if (!show) return null;

  const languages = [
    { code: 'en', label: 'English' },
    { code: 'es', label: 'Español' },
    // Agrega más idiomas según necesites
  ];

  const changeLanguage = (code) => {
    i18n.changeLanguage(code);
    onSelectLanguage(code);
  };

  return (
    <div className="modal-language-selector">
      <div className="modal-content-language-selector">
        <h4>{t('languageSelectorModal.select_language')}</h4>
        <ul>
          {languages.map((lang) => (
            <li key={lang.code} onClick={() => changeLanguage(lang.code)}>
              {lang.label}
            </li>
          ))}
        </ul>
        <button onClick={onClose}>{t('common.close')}</button>
      </div>
    </div>
  );
};

export default LanguageSelectorModal;