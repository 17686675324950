// src/App.js
import React, { useEffect } from 'react';
import { AuthProvider } from './contexts/AuthContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Routes from './routes';
import CookieConsentBar from './components/common/CookieConsentBar';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAuth from './hooks/useAuth';

const App = () => {
  const { i18n } = useTranslation();

  const { user, loading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!loading && user) {
      const redirectPath = location.state?.from?.pathname || null;
      const redirectSearch = location.state?.from?.search || '';

      if (redirectPath) {
        navigate(`${redirectPath}${redirectSearch}`, { replace: true });
      }
    }
  }, [user, loading, navigate, location]);

  // useEffect(() => {
  //   const savedLanguage = localStorage.getItem('language');
  //   const browserLanguage = navigator.language.slice(0, 2);
  //   const defaultLanguage = savedLanguage || browserLanguage || 'en';
  //   localStorage.setItem('language', defaultLanguage);
  // }, []);
  useEffect(() => {
    const savedLanguage = localStorage.getItem('i18nextLng');
    const browserLanguage = navigator.language.slice(0, 2);
    const defaultLanguage = savedLanguage || browserLanguage || 'en';
    i18n.changeLanguage(defaultLanguage);
  }, [i18n]);

  return (
    <AuthProvider>
      <CookieConsentBar />
      <Routes />
    </AuthProvider>
  );
};

export default App;