// src/components/modals/ProfileModal.jsx
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';
import Avatar from '../common/Avatar';
import '../../assets/styles/ProfileModal.css';

const ProfileModal = ({ show, onClose, user, handleGoToConfig, logout }) => {
  const { t } = useTranslation();

  const formatJoinDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', { month: 'short', day: 'numeric', year: 'numeric' });
  };

  if (!show) return null;

  return ReactDOM.createPortal(
    <div className="modal" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
         <div className="user-info-box-modal">
            <div className="user-info-header">
              <div className="user-avatar">
                <Avatar
                  url={user.data_user.avatar_url}
                  alt={user.name}
                />
              </div>
              <div className="user-details">
                <h5>{user.name || t('profileModal.user_label')}</h5>
                <p>{t('profileModal.member_since_label')}</p> 
                <p>{user.created_at ? formatJoinDate(user.created_at) : t('profileModal.register_at')}</p>
              </div>
            </div>
            <div className="user-actions">
              <button onClick={handleGoToConfig} className="action-btn">{t('profileModal.configuration')}</button>
              <button onClick={logout} className="action-btn">{t('common.logout')}</button>
            </div>
         </div>
      </div>
    </div>,
    document.getElementById('modal-root'),
    document.getElementById('modal-root')
  );
};

export default ProfileModal;