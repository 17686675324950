// src/services/miscellaneousService.js
import API from '../api';

const miscellaneousService = {
  
  /**
   * Envía un mensaje de contacto al backend.
   * @param {Object} data - Datos del formulario de contacto.
   * @returns {Promise}
   */
  sendContactMessage: (data) => API.post('/contact', data),


  /**
   * Get a legal document data from the backend
   * @param {Object} data
   * @returns {Promise}
   * */
  legalDocument: (data) => API.post('/legal-documents', data),
  
};

export default miscellaneousService;