// src/pages/Config.jsx
import React, { useEffect, useState } from 'react';
import TopWishesMobileButton from '../components/specific/TopWishesMobileButton';
import PreferencesForm from '../components/forms/PreferencesForm';
import SecurityForm from '../components/forms/SecurityForm';
import MainLayout from '../components/layout/MainLayout';
import TopWishes from '../components/specific/TopWishes';
import PerfilForm from '../components/forms/PerfilForm';
import UserWallet from '../components/forms/UserWallet';
import WishForm from '../components/specific/WishForm';
import useWindowWidth from '../hooks/useWindowWidth';
import wishService from '../services/wishService';
import { useTranslation } from 'react-i18next';
import { Tabs, Tab } from 'react-bootstrap';
import SEO from '../components/common/SEO';
import useAuth from '../hooks/useAuth';
import '../assets/styles/StaticPageContent.css';
import '../assets/styles/Config.css';

const Config = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [wishes, setWishes] = useState([]);
  const [loadingWishes, setLoadingWishes] = useState(true);
  const [errorWishes, setErrorWishes] = useState(null);
  const language = localStorage.getItem('language') || 'en';
  const width = useWindowWidth();
  const isMobile = width < 768;

  useEffect(() => {
    // Función para obtener todos los deseos
    const fetchAllWishes = async () => {
      try {
        setLoadingWishes(true);
        const response = await wishService.getAllWishes();
        if (response.data.status) {
          setWishes(response.data.data.wishes.data);
        } else {
          setErrorWishes(new Error(t('common.error_loading_wishes')));
        }
      } catch (err) {
        setErrorWishes(err);
      } finally {
        setLoadingWishes(false);
      }
    };

    fetchAllWishes();
  }, [ t ]);

  // Función para actualizar un deseo (por ejemplo, likes, hearts)
  const updateWish = (updatedWish) => {
    setWishes((prevWishes) =>
      prevWishes.map((wish) =>
        wish.wishNumber === updatedWish.wishNumber ? updatedWish : wish
      )
    );
  };

  return (
    <MainLayout>

      <SEO 
        titleKey="seo.config.title"
        descriptionKey="seo.config.description"
        keywordsKey="seo.config.keywords"
        lang={language}
        url={`${window.location.origin}/${language}/config`}
        image={`${window.location.origin}/images/config-page-banner.jpg`}
        siteTwitter="@andres_elias"
        authorTwitter="@andres_elias"
        localeAlternates={['en_US', 'es_ES']}
        structuredData={{
          '@context': 'https://schema.org',
          '@type': 'WebPage',
          name: t('seo.config.title'),
          description: t('seo.config.description'),
          url: `${window.location.origin}/${language}/config`,
          mainEntity: {
            '@type': 'Person',
            name: user.name,
          },
        }}
      />

      <div className="main-container">
        {/* Left Sidebar */}
        <aside className="left-sidebar">
          <WishForm />
        </aside>

        {/* Config */}
        <section className="config-container">
            <div className="config-form">
              <h4>{t('common.account_configuration')}</h4>
              <Tabs defaultActiveKey="userWallet" id="configTabs" className="mb-3 custom-tabs">
              <Tab eventKey="userWallet" title={t('common.wallet')}>
                <UserWallet />
              </Tab>
              <Tab eventKey="perfil" title={t('common.profile_title')}>
                <PerfilForm />
              </Tab>
              <Tab eventKey="seguridad" title={t('common.security_title')}>
                <SecurityForm />
              </Tab>
              <Tab eventKey="preferencias" title={t('common.preferences_title')}>
                <PreferencesForm />
              </Tab>
            </Tabs>
            </div>
        </section>

        {/* Right Sidebar */}
        <aside className="right-sidebar">
          {!isMobile && (
            <TopWishes
              wishes={wishes} 
              onUpdateWish={updateWish}
              loading={loadingWishes}
              error={errorWishes}
            />
          )}

          {isMobile && (
            <TopWishesMobileButton
              wishes={wishes}
              onUpdateWish={updateWish}
              loading={loadingWishes}
              error={errorWishes}
            />
          )}
        </aside>
      </div>
    </MainLayout>
  );
};

export default Config;