// src/services/authService.js
import API from '../api';

const authService = {
  login: async (credentials) => {
    try {
      const res = await API.post('/login', credentials);
      const token = res.data.data.token;
      localStorage.setItem('token', token);
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  register: async (userData) => {
    try {
      const res = await API.post('/register', userData);
      if (res.data.status) {
        const token = res.data.data.token;
        localStorage.setItem('token', token);
        return res.data;
      } else {
        throw res.data;
      }
    } catch (error) {
      throw error;
    }
  },
  
  logout: async () => {
    try {
      const res = await API.post('/logout');
      localStorage.removeItem('token');
      return res;
    } catch (error) {
      throw error;
    }
  },

  getProfile: async () => {
    try {
      const res = await API.get('/user/get-profile');
      if (res.data.status) {
        return res.data;
      } else {
        throw res.data;
      }
    } catch (error) {
      throw error;
    }
  },

  updatePassword: async (currentPassword, newPassword, newPasswordConfirmation) => {
    try {
      const payload = {
        current_password: currentPassword,
        new_password: newPassword,
        new_password_confirmation: newPasswordConfirmation
      };
      const res = await API.post('/update-password', payload);
      if (res.data.status) {
        return res.data;
      } else {
        throw res.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        throw error.response.data;
      } else {
        throw { message: 'Error al actualizar la contraseña.' };
      }
    }
  },
};

export default authService;

