// src/components/specific/TopWishesMobileButton.jsx
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import TopWishes from './TopWishes';
import '../../assets/styles/TopWishesMobileButton.css';

const TopWishesMobileButton = ({ wishes, onUpdateWish, loading, error }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <>
      <button className="top-10-button" onClick={handleShow}>
        {t('common.top10_title')}
      </button>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Body>
          <TopWishes
            wishes={wishes}
            onUpdateWish={onUpdateWish}
            loading={loading}
            error={error}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TopWishesMobileButton;