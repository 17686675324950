import React from 'react';
import '../../assets/styles/LoadingWave.css';

const LoadingWave = ({ barSize = 5 }) => {
  return (
    <div className="tmw-loading-animation">
      {[...Array(5)].map((_, index) => (
        <div
          key={index}
          className="tmw-bar"
          style={{
            width: `${barSize}px`,
            height: `${barSize * 5}px`,
          }}
        ></div>
      ))}
    </div>
  );
};

export default LoadingWave;