// src/components/modals/PaymentOptionsModal.jsx
import React from 'react';
import ReactDOM from 'react-dom';
import { FaCreditCard, FaPaypal, FaBitcoin, FaTimes } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import '../../assets/styles/PaymentOptionsModal.css';

const PaymentOptionsModal = ({ show, onClose, onSelectPayment }) => {
  const { t } = useTranslation();

  if (!show) return null;
  
  return ReactDOM.createPortal(
    <div className="payment-modal-overlay" onClick={onClose}>
      <div className="payment-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="payment-modal-header">
          <h4>{t('paymentOptionsModal.pay_with_title')}</h4>
          <button className="close-btn" onClick={onClose}>
            <FaTimes />
          </button>
        </div>
        <div className="payment-options">
          <button className="payment-option-btn" onClick={() => onSelectPayment('stripe')}>
            <FaCreditCard size={24} />
            <span>{t('paymentOptionsModal.card_credit_debit_label')}</span>
          </button>
          <button className="payment-option-btn" onClick={() => onSelectPayment('paypal')}>
            <FaPaypal size={24} />
            <span>{t('paymentOptionsModal.paypal_label')}</span>
          </button>
          {/*<button className="payment-option-btn" onClick={() => onSelectPayment('crypto')}>
            <FaBitcoin size={24} />
            <span>{t('paymentOptionsModal.crypto_currency_label')}</span>
          </button>*/}
        </div>
        <button className="cancel-btn" onClick={onClose}>
          {t('common.close')}
        </button>
      </div>
    </div>,
    document.getElementById('modal-root')
  );
};

export default PaymentOptionsModal;