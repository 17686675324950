// src/components/modals/MessageModal.jsx
import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import '../../assets/styles/MessageModal.css';

const MessageModal = ({ show, type, message, onClose }) => {
  const { t } = useTranslation();

  const getModalStyle = () => {
    switch (type) {
      case 'error':
        return 'message-modal error';
      case 'warning':
        return 'message-modal warning';
      case 'info':
        return 'message-modal info';
      default:
        return 'message-modal';
    }
  };

  const getIcon = () => {
    switch (type) {
      case 'error':
        return <i className="bi bi-exclamation-triangle-fill"></i>;
      case 'warning':
        return <i className="bi bi-exclamation-circle-fill"></i>;
      case 'info':
        return <i className="bi bi-info-circle-fill"></i>;
      default:
        return null;
    }
  };

  if (!show) return null;

  return ReactDOM.createPortal(
    <div className="modal-overlay" onClick={onClose}>
      <div className={getModalStyle()} onClick={(e) => e.stopPropagation()}>
        <div className="modal-icon">{getIcon()}</div>
        <div className="modal-message">{message}</div>
        <button className="close-btn" onClick={onClose}>
          {t('common.close')}
        </button>
      </div>
    </div>,
    document.getElementById('modal-root')
  );
};

export default MessageModal;