// src/components/layout/MainLayout.jsx
import React from 'react';

import { CONSTANTS } from '../../config/constants';
import { useTranslation } from 'react-i18next';
import Header from './Header';
import Footer from './Footer';
import '../../assets/styles/MainLayout.css'; 

//CONSTANTS.REACT_APP_ENV

const MainLayout = ({ children }) => {
  const { t } = useTranslation();

  const repeatText = t('common.dev_mode');
  const repeatedText = Array(100).fill(repeatText).join('');

  return(
    <>
      {CONSTANTS.ENV === 'development' && (
        <div className="development-banner">
          <div className="marquee">
            <span>{repeatedText}</span>
          </div>
        </div>
      )}
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default MainLayout;