// // src/components/common/SEO.js
// import React from 'react';
// import { Helmet } from 'react-helmet-async';
// import { useTranslation } from 'react-i18next';

// const SEO = ({
//   titleKey,
//   descriptionKey,
//   keywordsKey,
//   lang,
//   image,
//   url,
//   type = 'website',
//   authorTwitter,
//   siteTwitter,
//   localeAlternates = [],
// }) => {
//   const { t } = useTranslation();

//   const pageTitle = t(titleKey);
//   const pageDescription = t(descriptionKey);
//   const pageKeywords = t(keywordsKey);
//   const pageLang = lang || 'en';
//   const pageUrl = url || window.location.href;
//   const pageImage = image || `${window.location.origin}/logo.png`;

//   return (
//     <Helmet>
//       <html lang={pageLang} />
//       <title>{pageTitle}</title>
//       <meta name="description" content={pageDescription} />
//       <meta name="keywords" content={pageKeywords} />
//       <meta name="author" content="The Million Wall" />
//       <meta name="viewport" content="width=device-width, initial-scale=1" />
//       <meta name="robots" content="index, follow" />

//       {/* Canonical URL */}
//       <link rel="canonical" href={pageUrl} />

//       {/* Open Graph / Facebook */}
//       <meta property="og:title" content={pageTitle} />
//       <meta property="og:description" content={pageDescription} />
//       <meta property="og:type" content={type} />
//       <meta property="og:locale" content={pageLang} />
//       <meta property="og:url" content={pageUrl} />
//       <meta property="og:image" content={pageImage} />
//       <meta property="og:image:width" content="1200" />
//       <meta property="og:image:height" content="630" />
//       <meta property="og:image:type" content="image/png" />
//       <meta property="og:image:alt" content={pageTitle} />
//       <meta property="og:site_name" content="The Million Wall" />

//       {/* Locale Alternates */}
//       {localeAlternates.map((alternateLang) => (
//         <meta
//           key={alternateLang}
//           property="og:locale:alternate"
//           content={alternateLang}
//         />
//       ))}

//       {/* Twitter */}
//       <meta name="twitter:card" content="summary_large_image" />
//       <meta name="twitter:title" content={pageTitle} />
//       <meta name="twitter:description" content={pageDescription} />
//       <meta name="twitter:image" content={pageImage} />
//       <meta name="twitter:image:alt" content={pageTitle} />
//       {siteTwitter && <meta name="twitter:site" content={siteTwitter} />}
//       {authorTwitter && <meta name="twitter:creator" content={authorTwitter} />}

//       {/* Structured Data */}
//       <script type="application/ld+json">
//         {JSON.stringify({
//           '@context': 'https://schema.org',
//           '@type': 'WebSite',
//           name: pageTitle,
//           description: pageDescription,
//           url: pageUrl,
//           author: {
//             "@type": "Organization",
//             name: "TheMillionWall",
//           },
//           mainEntity: {
//             "@type": "WebPageElement",
//             name: pageTitle,
//             text: pageDescription,
//           },
//           potentialAction: {
//             '@type': 'SearchAction',
//             target: `${window.location.origin}/search?q={search_term_string}`,
//             'query-input': 'required name=search_term_string',
//           },
//         })}
//       </script>
//     </Helmet>
//   );
// };

// export default SEO;


import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const SEO = ({
  titleKey,
  descriptionKey,
  keywordsKey,
  lang,
  image,
  url,
  type = 'website',
  authorTwitter,
  siteTwitter,
  localeAlternates = [],
  structuredData = null
}) => {
  const { t } = useTranslation();

  const pageTitle = t(titleKey) || 'TheMillionWall';
  const pageDescription = t(descriptionKey) || 'Explora deseos inspiradores en TheMillionWall.';
  const pageKeywords = t(keywordsKey) || 'deseos, comunidad, sorteos, TheMillionWall';
  const pageLang = lang || 'en';
  const pageUrl = url || window.location.href;
  const pageImage = image || `${window.location.origin}/logo.png`;

  return (
    <Helmet>
      <html lang={pageLang} />
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      <meta name="keywords" content={pageKeywords} />
      <meta name="author" content="TheMillionWall" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="robots" content="index, follow" />

      {/* Canonical URL */}
      <link rel="canonical" href={pageUrl} />

      {/* Open Graph / Facebook */}
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageDescription} />
      <meta property="og:type" content={type} />
      <meta property="og:locale" content={pageLang} />
      <meta property="og:url" content={pageUrl} />
      <meta property="og:image" content={pageImage} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:alt" content={pageTitle} />
      <meta property="og:site_name" content="TheMillionWall" />

      {/* Locale Alternates */}
      {localeAlternates.map((alternateLang) => (
        <meta
          key={alternateLang}
          property="og:locale:alternate"
          content={alternateLang}
        />
      ))}

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={pageDescription} />
      <meta name="twitter:image" content={pageImage} />
      <meta name="twitter:image:alt" content={pageTitle} />
      {siteTwitter && <meta name="twitter:site" content={siteTwitter} />}
      {authorTwitter && <meta name="twitter:creator" content={authorTwitter} />}

      {/* Structured Data */}
      {structuredData && (
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      )}
    </Helmet>
  );
};

export default SEO;