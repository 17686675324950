// src/pages/WishPage.jsx
import React, { useEffect, useState } from 'react';
import TopWishesMobileButton from '../components/specific/TopWishesMobileButton';
import miscellaneousService from '../services/miscellaneousService';
import WishDetail from '../components/specific/WishDetail';
import MainLayout from '../components/layout/MainLayout';
import TopWishes from '../components/specific/TopWishes';
import WishForm from '../components/specific/WishForm';
import useWindowWidth from '../hooks/useWindowWidth';
import wishService from '../services/wishService';
import { Spinner, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import SEO from '../components/common/SEO';
import DOMPurify from 'dompurify';
import '../assets/styles/StaticPageContent.css';
import '../assets/styles/WishPage.css';

const WishPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [wishes, setWishes] = useState([]);
  const [loadingWishes, setLoadingWishes] = useState(true);
  const [errorWishes, setErrorWishes] = useState(null);
  const [privacyContent, setPrivacyContent] = useState('');
  const [loadingPrivacy, setLoadingPrivacy] = useState(true);
  const [errorPrivacy, setErrorPrivacy] = useState(null);
  const language = localStorage.getItem('language') || 'en';
  const width = useWindowWidth();
  const isMobile = width < 768;

  const [wish, setWish] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Función para obtener todos los deseos
    const fetchAllWishes = async () => {
      try {
        setLoadingWishes(true);
        const response = await wishService.getAllWishes();
        if (response.data.status) {
          setWishes(response.data.data.wishes.data);
        } else {
          setErrorWishes(new Error(t('common.error_loading_wishes')));
        }
      } catch (err) {
        setErrorWishes(err);
      } finally {
        setLoadingWishes(false);
      }
    };

    fetchAllWishes();
  }, []);

  useEffect(() => {
    const fetchWish = async () => {
      try {
        const response = await wishService.getWish(id);
        if (response.data.status) {
          setWish(response.data.data);
        } else {
          setError(new Error(t('wishPage.error_fetching_wish')));
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchWish();
  }, [id, t]);
  

  // Función para actualizar un deseo (por ejemplo, likes, hearts)
  const updateWish = (updatedWish) => {
    setWish(updatedWish);
    setWishes((prevWishes) =>
      prevWishes.map((wish) =>
        wish.id === updatedWish.id ? updatedWish : wish
      )
    );
  };


  // if (loading) {
  //   return (
  //     <div className="wish-page">
  //       <Spinner animation="border" variant="success" />
  //       <p>{t('wishPage.loading')}</p>
  //     </div>
  //   );
  // }

  if (error) {
    return (
      <div className="wish-page">
        <Alert variant="danger">
          {t('wishPage.error_message')}: {error.message}
        </Alert>
      </div>
    );
  }

  return (
    <MainLayout>

      <SEO
        titleKey={wish?.content || 'The Million Wall - Deseo'}
        descriptionKey={`"${wish?.content}" publicado por ${wish?.user?.name || 'un usuario'}. Tiene ${wish?.likes || 0} likes y ${wish?.hearts || 0} corazones. Únete y publica tu deseo.`}
        keywordsKey={`deseo, ${wish?.content}, The Million Wall, deseos, comunidad`}
        lang={language}
        url={`${window.location.origin}/${language}/wish/${id}`}
        image={wish?.user?.data_user?.avatar_url || `${window.location.origin}/images/homepage-banner.jpg`}
        siteTwitter="@andres_elias"
        authorTwitter="@andres_elias"
        localeAlternates={['en_US', 'es_ES']}
        structuredData={{
          '@context': 'https://schema.org',
          '@type': 'CreativeWork',
          name: wish?.content || 'Deseo',
          author: {
            '@type': 'Person',
            name: wish?.user?.name || 'Usuario anónimo',
            image: wish?.user?.data_user?.avatar_url || '',
          },
          datePublished: wish?.created_at,
          interactionStatistic: [
            {
              '@type': 'InteractionCounter',
              interactionType: 'https://schema.org/LikeAction',
              userInteractionCount: wish?.likes || 0,
            },
            {
              '@type': 'InteractionCounter',
              interactionType: 'https://schema.org/ReactAction',
              userInteractionCount: wish?.hearts || 0,
            },
          ],
          description: wish?.content || '',
          url: `${window.location.origin}/${language}/wish/${id}`,
        }}
      />

      <div className="main-container">
        {/* Left Sidebar */}
        <aside className="left-sidebar hide-on-mobile">
          <WishForm />
        </aside>

        
        <section className="static-page-content">
          <WishDetail wish={wish} onUpdateWish={updateWish} loading={loading} />
        </section>
        

        {/* Right Sidebar */}
        <aside className="right-sidebar">
          {!isMobile && (
            <TopWishes 
              wishes={wishes} 
              onUpdateWish={updateWish} 
              loading={loadingWishes}
              error={errorWishes}
            />
          )}

          {isMobile && (
            <TopWishesMobileButton
              wishes={wishes}
              onUpdateWish={updateWish}
              loading={loadingWishes}
              error={errorWishes}
            />
          )}
        </aside>
        
      </div>
    </MainLayout>
  );
};

export default WishPage;
