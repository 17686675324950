// src/components/specific/TopWishes.jsx
import React from 'react';

import LoadingWave from '../common/LoadingWave';
import { useTranslation } from 'react-i18next';
import TopWishCard from './TopWishCard';
import { Alert } from 'react-bootstrap';
import '../../assets/styles/TopWishes.css';

const TopWishes = ({ wishes, onUpdateWish, loading, error }) => {

  const { t } = useTranslation();
  
  const topWishes = [...wishes]
    .filter(wish => wish.likes > 0)
    .sort((a, b) => b.likes - a.likes)
    .slice(0, 10);

  return (
    <div className="top-wishes-container">
      <h4>{t('topWishes.top_10_wishes_title')}</h4>
      
      {loading ? (
        <div>
          <LoadingWave barSize="2" />
          <div className="spinner-container">
            <span className="spinner-text">{t('topWishes.loading_top_wishes')}</span>
          </div>
        </div>

      ) : error ? (
        <Alert variant="danger">
          {t('topWishes.error_loading_top_whises')} : {error.message}
        </Alert>

      ) : topWishes.length > 0 ? (
        <div className="top-wishes">
          {topWishes.map((wish, index) => (
            <TopWishCard key={wish.id} wish={wish} rank={index + 1} onUpdateWish={onUpdateWish} />
          ))}
        </div>
        
      ) : (
        <p className="no-wishes-message">{t('topWishes.no_wishes_with_likes_to_show')}.</p>
      )}
    </div>
  );
};

export default TopWishes;