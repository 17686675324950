// src/components/specific/WishCard.jsx
import React, { useEffect, useState, useCallback } from 'react';
import AuthPromptModal from '../modals/AuthPromptModal';
import wishService from '../../services/wishService';
import RegisterModal from '../modals/RegisterModal';
import MessageModal from '../modals/MessageModal';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatDistanceToNow } from 'date-fns';
import LoginModal from '../modals/LoginModal';
import useAuth from '../../hooks/useAuth';
import HashtagText from "./HashtagText";
import Avatar from '../common/Avatar';
import '../../assets/styles/WishCard.css';

const WishCard = ({ wish, onUpdateWish }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [showAuthPromptModal, setShowAuthPromptModal] = useState(false);
  // const [pendingReaction, setPendingReaction] = useState(null);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [userReactions, setUserReactions] = useState([]);
  const [modalMessage, setModalMessage] = useState('');
  const [modalType, setModalType] = useState('info');
  const navigate = useNavigate();

  const handleReaction = useCallback(
    async (reactionType) => {
      if (!user) {
        // setPendingReaction(reactionType);
        setShowAuthPromptModal(true);
        return;
      }

      try {
        // Llamar al servicio para enviar la reacción
        const response = await wishService.reactToWish(wish.id, {
          reaction_type: reactionType,
          device_id: navigator.userAgent,
        });

        if (response.data.status) {
          const isRemoved = response.data.data?.removed;

          // Actualizar el deseo basado en la respuesta
          const updatedWish = {
            ...wish,
            likes: reactionType === 'like' ? wish.likes + (isRemoved ? -1 : 1) : wish.likes,
            hearts: reactionType === 'heart' ? wish.hearts + (isRemoved ? -1 : 1) : wish.hearts,
            dislikes: reactionType === 'dislike' ? wish.dislikes + (isRemoved ? -1 : 1) : wish.dislikes,
            votes: isRemoved
              ? wish.votes.filter(
                  (vote) =>
                    !(vote.user_id === user.id && vote.reaction_type === reactionType)
                )
              : [...wish.votes, response.data.data.vote],
          };

          onUpdateWish(updatedWish);
          
        } else {
          setModalType('warning');
          setModalMessage(t(`errors.${response.data.message}`) || t('errors.something_went_wrong_try_again'));
          setShowMessageModal(true);
        }
      } catch (error) {
        setModalType('error');
        setModalMessage(
          t(`errors.${error.response?.data?.message}`) || t('errors.error_reacting_to_wish_try_again')
        );
        setShowMessageModal(true);
      }
    },
    [wish, onUpdateWish, user, t]
  );


  const handleLoginClick = () => {
    setShowAuthPromptModal(false);
    setShowLoginModal(true);
  };


  const handleRegisterClick = () => {
    setShowAuthPromptModal(false);
    setShowRegisterModal(true);
  };


  // const applyPendingReaction = useCallback(async () => {
  //   if (pendingReaction) {
  //     await handleReaction(pendingReaction);
  //     setPendingReaction(null);
  //   }
  // }, [pendingReaction, handleReaction]);



  const handleShare = () => {
    navigator.clipboard.writeText(window.location.href)
      .then(() => alert(t('wishCard.link_copied_to_clipboard')))
      .catch(() => alert(t('wishCard.error_copying_link')));
  };


  const handleWishClick = () => {
    const language = localStorage.getItem('language') || 'en';
    navigate(`/${language}/wish/${wish.id}`);
  };


  // Identificar las reacciones específicas del usuario autenticado
  useEffect(() => {
    if (user && wish.votes) {
      const reactions = wish.votes
        .filter((vote) => vote.user_id === user.id)
        .map((vote) => vote.reaction_type);
      setUserReactions(reactions);
    } else {
      setUserReactions([]);
    }
  }, [user, wish]);


  // useEffect(() => {
  //   if (user && pendingReaction) {
  //     applyPendingReaction();
  //   }
  // }, [user, pendingReaction, applyPendingReaction]);


  if (!wish) return null;

  return (
    <div className="wish-card">
      <div className="wish-number" onClick={handleWishClick}>
        {t('wishCard.wish_label')} #{wish.number_sequence}
      </div>
      <div className="user-info">
        <Avatar
          url={wish.user.data_user.avatar_url}
          alt={wish.user.name}
          className="reaction-avatar"
        />
        <span className="fw-bold">{wish.user.name}</span>
      </div>
      <img
        className="flag"
        src={
          wish.user?.data_user?.country?.country_code
            ? `https://flagcdn.com/${wish.user.data_user.country.country_code.toLowerCase()}.svg`
            : 'https://upload.wikimedia.org/wikipedia/commons/b/b0/No_flag.svg' // Ruta a una imagen por defecto
        }
        alt={
          wish.user?.data_user?.country?.name || t('wishCard.country_flag_label')
        }
      />
      <HashtagText text={wish.content} />
      <div className="reactions">
        <div className="reaction-buttons">
          <button
            className={`reaction-btn like-btn ${userReactions.includes('like') ? 'active' : ''}`}
            onClick={() => handleReaction('like')}
          >
            <i className={`bi ${userReactions.includes('like') ? 'bi-hand-thumbs-up-fill' : 'bi-hand-thumbs-up'}`}></i> {wish.likes}
          </button>

          <button
            className={`reaction-btn heart-btn ${userReactions.includes('heart') ? 'active' : ''}`}
            onClick={() => handleReaction('heart')}
          >
            <i className={`bi ${userReactions.includes('heart') ? 'bi-heart-fill' : 'bi-heart'}`}></i> {wish.hearts}
          </button>

          <button
            className={`reaction-btn dislike-btn ${userReactions.includes('dislike') ? 'active' : ''}`}
            onClick={() => handleReaction('dislike')}
          >
            <i className={`bi ${userReactions.includes('dislike') ? 'bi-hand-thumbs-down-fill' : 'bi-hand-thumbs-down'}`}></i> {wish.dislikes}
          </button>

          <button className="reaction-btn " onClick={handleShare}>
            <i className="bi bi-share"></i>
          </button>
        </div>
      </div>

      <small className="text-muted time-stamp">
        {formatDistanceToNow(new Date(wish.created_at), { addSuffix: true })}
      </small>

      {/* Modales */}
      <LoginModal show={showLoginModal} onClose={() => setShowLoginModal(false)} />

      <RegisterModal show={showRegisterModal} onClose={() => setShowRegisterModal(false)} />
      
      <AuthPromptModal
        show={showAuthPromptModal}
        onClose={() => setShowAuthPromptModal(false)}
        onLogin={handleLoginClick}
        onRegister={handleRegisterClick}
        title={t('wishCard.to_react_to_this_wish_you_need')}
      />
      
      <MessageModal
        show={showMessageModal}
        type={modalType}
        message={modalMessage}
        onClose={() => setShowMessageModal(false)}
      />
    </div>
  );
};

export default WishCard;