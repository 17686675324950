// src/contexts/AuthContext.js
import React, { createContext, useState, useEffect, useCallback } from 'react';
import authService from '../services/authService';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { t } = useTranslation();
  const [user, setUser] = useState(null);
  const [userTemp, setUserTemp] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState(null);
  const navigate = useNavigate();


  // Verificar si hay un token almacenado al montar el componente
  useEffect(() => {
    const token = localStorage.getItem('token');

    const fetchUser = async () => {
      try {
        setLoading(true);
        setLoadingText(t('common.loading_user_info'));
        const response = await authService.getProfile();
        setUser(response.data.user);

      } catch (error) {
        setUser(null);

      } finally {
        setLoading(false);
        setLoadingText(null);
      }
    };

    if (token) {
      fetchUser();
    } else {
      setLoading(false);
    }
  }, []);


  // Memoizar las funciones para evitar recreaciones en cada render
  const login = useCallback(async (credentials) => {
    try {
      setLoading(true);
      setLoadingText(t('common.logging_in'));

      const response = await authService.login(credentials);

      if (response.data.requireCode) {
        setUserTemp(response.data.user);
      } else {
        setUser(response.data.user);
      }

      return response.data;

    } catch (error) {
      throw error;

    } finally {
      setLoading(false); 
      setLoadingText(null);
    }
  }, []);


  const register = useCallback(async (userData) => {
    try {
      setLoading(true);
      setLoadingText(t('common.registering'));

      const data = {
        ...userData,
        email_subject: t('email.verify_email_subject'),
        email_body: t('email.verify_email_body')
      };

      const response = await authService.register(data);
      setUser(response.data.user);
      return response.data;

    } catch (error) {
      throw error;

    } finally {
      setLoading(false); 
      setLoadingText(null);
    }
  }, []);


  const logout = useCallback(async () => {
    try {
      setLoading(true);
      setLoadingText(t('common.logging_out'));
      await authService.logout();

    } catch (error) {
      console.error('Error al cerrar sesión:', error);

    } finally {
      setLoading(false);
      setLoadingText(null);
      setUser(null);
      navigate('/');
    }
  }, [navigate]);


  const refreshUser = useCallback(async () => {
    try {
      const response = await authService.getProfile();
      setUser(response.data.user);
    } catch (error) {
      setUser(null);
    }
  }, []);


  const completeLogin = useCallback(() => {
    setUser(userTemp);
  }, [userTemp]);


  return (
    <AuthContext.Provider value={{ user, loading, loadingText, setUser, login, register, logout, refreshUser, completeLogin }}>
      {children}
    </AuthContext.Provider>
  );
};

