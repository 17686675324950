// src/components/modals/AuthPromptModal.jsx
import React from 'react';

import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import '../../assets/styles/AuthPromptModal.css';

const AuthPromptModal = ({ show, onClose, onLogin, onRegister, title='' }) => {
  const { t } = useTranslation();

  if (!show) return null;

  if (!title) title = t('common.to_publish_you_need_to');

  return ReactDOM.createPortal(
    <div className="modal" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h4>{title}:</h4>
        <div className="button-group">
          <button className="login-btn" onClick={onLogin}>{t('authPromptModal.login')}</button>
          <span className="textCenter">o</span>
          <button className="register-btn" onClick={onRegister}>{t('authPromptModal.register')}</button>
        </div>
      </div>
    </div>,
    document.getElementById('modal-root')
  );
};

export default AuthPromptModal;