// src/pages/PrivacyPolicy.jsx
import React, { useEffect, useState } from 'react';
import TopWishesMobileButton from '../components/specific/TopWishesMobileButton';
import miscellaneousService from '../services/miscellaneousService';
import LoadingWave from '../components/common/LoadingWave';
import MainLayout from '../components/layout/MainLayout';
import TopWishes from '../components/specific/TopWishes';
import WishForm from '../components/specific/WishForm';
import useWindowWidth from '../hooks/useWindowWidth';
import wishService from '../services/wishService';
import { Spinner, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SEO from '../components/common/SEO';
import DOMPurify from 'dompurify';
import '../assets/styles/StaticPageContent.css';

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const [wishes, setWishes] = useState([]);
  const [loadingWishes, setLoadingWishes] = useState(true);
  const [errorWishes, setErrorWishes] = useState(null);
  const [privacyContent, setPrivacyContent] = useState('');
  const [loadingPrivacy, setLoadingPrivacy] = useState(true);
  const [errorPrivacy, setErrorPrivacy] = useState(null);
  const language = localStorage.getItem('language') || 'en';
  const width = useWindowWidth();
  const isMobile = width < 768;

  useEffect(() => {
    // Función para obtener todos los deseos
    const fetchAllWishes = async () => {
      try {
        setLoadingWishes(true);
        const response = await wishService.getAllWishes();
        if (response.data.status) {
          setWishes(response.data.data.wishes.data);
        } else {
          setErrorWishes(new Error(t('common.error_loading_wishes')));
        }
      } catch (err) {
        setErrorWishes(err);
      } finally {
        setLoadingWishes(false);
      }
    };

    fetchAllWishes();
  }, []);


  useEffect(() => {
    // Función para obtener el documento de privacidad
    const fetchPrivacyPolicy = async () => {
      try {
        setLoadingPrivacy(true);
        const response = await miscellaneousService.legalDocument({
          document_type: 'privacy',
          language_code: language,
        });

        if (response.data.status && response.data.data.content) {
          setPrivacyContent(response.data.data.content);
        } else {
          setErrorPrivacy(new Error(t('common.error_loading_content')));
        }
      } catch (err) {
        setErrorPrivacy(err);
      } finally {
        setLoadingPrivacy(false);
      }
    };

    fetchPrivacyPolicy();
  }, []);


  // Función para actualizar un deseo (por ejemplo, likes, hearts)
  const updateWish = (updatedWish) => {
    setWishes((prevWishes) =>
      prevWishes.map((wish) =>
        wish.wishNumber === updatedWish.wishNumber ? updatedWish : wish
      )
    );
  };

  return (
    <MainLayout>

      <SEO 
        titleKey="seo.privacy_policy.title"
        descriptionKey="seo.privacy_policy.description"
        keywordsKey="seo.privacy_policy.keywords"
        lang={language}
        url={`${window.location.origin}/${language}/privacy-policy`}
        image={`${window.location.origin}/images/privacy-policy-banner.jpg`}
        siteTwitter="@andres_elias"
        authorTwitter="@andres_elias"
        localeAlternates={['en_US', 'es_ES']}
        structuredData={{
          '@context': 'https://schema.org',
          '@type': 'WebPage',
          name: t('seo.privacy_policy.title'),
          description: t('seo.privacy_policy.description'),
          url: `${window.location.origin}/${language}/privacy-policy`,
          mainEntity: {
            '@type': 'CreativeWork',
            name: t('seo.privacy_policy.title'),
            text: t('seo.privacy_policy.description'),
          },
        }}
      />

      <div className="main-container">
        {/* Left Sidebar */}
        <aside className="left-sidebar hide-on-mobile">
          <WishForm />
        </aside>

        {/* Center Content (Privacy Policy) */}
        <section className="static-page-content">
          {loadingPrivacy ? (
            <div>
              <LoadingWave />
              <div className="spinner-container">
                <span className="spinner-text">{t('common.loading_content')}</span>
              </div>
            </div>
          ) : errorPrivacy ? (
            <Alert variant="danger">
              {t('common.loading_content')}: {errorPrivacy.message}
            </Alert>
          ) : (
            <div>
              <h2>{t('common.privacy_policy_title')}</h2>
              <div
                className="privacy-content"
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(privacyContent.replace(/\r\n/g, '<br />')) }}
              />
            </div>
          )}
        </section>
        

        {/* Right Sidebar */}
        <aside className="right-sidebar">
          {!isMobile && (
            <TopWishes 
              wishes={wishes} 
              onUpdateWish={updateWish} 
              loading={loadingWishes}
              error={errorWishes}
            />
          )}

          {isMobile && (
            <TopWishesMobileButton
              wishes={wishes}
              onUpdateWish={updateWish}
              loading={loadingWishes}
              error={errorWishes}
            />
          )}
        </aside>
        
      </div>
    </MainLayout>
  );
};

export default PrivacyPolicy;