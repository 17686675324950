// src/services/georeferencesServices.js
import API from '../api';

const georeferencesServices = {
  getCountries: (country = '') => {
    const url = country ? `/countries?country=${country}` : '/countries';
    return API.get(url);
  },
};

export default georeferencesServices;