// src/components/modals/CustomizeCookiesModal.jsx
import React, { useState, useEffect } from 'react';

import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const CustomizeCookiesModal = ({ show, handleClose, hideCookiesConsentBar }) => {
  const { t } = useTranslation();

  const [preferences, setPreferences] = useState({
    performance: false,
    functionality: false,
    advertising: false,
  });

  // Cargar preferencias desde localStorage al abrir el modal
  useEffect(() => {
    if (show) {
      const storedPreferences = JSON.parse(localStorage.getItem('cookieConsent')) || {};
      setPreferences({
        performance: storedPreferences.performance || false,
        functionality: storedPreferences.functionality || false,
        advertising: storedPreferences.advertising || false,
      });
    }
  }, [show]);

  // Manejar cambios en los checkboxes
  const handleChange = (e) => {
    const { name, checked } = e.target;
    setPreferences((prev) => ({ ...prev, [name]: checked }));
  };

  // Guardar preferencias en localStorage
  const handleSave = () => {
    const consent = {
      essential: true, // Siempre activas
      ...preferences,
    };
    localStorage.setItem('cookieConsent', JSON.stringify(consent));
    handleClose();
    hideCookiesConsentBar();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t('customizeCookiesModal.cookies_configuration_label')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {/* Cookies esenciales siempre activas */}
          <Form.Group controlId="essentialCookies">
            <Form.Check
              type="checkbox"
              label={t('customizeCookiesModal.esensible_cookies_label')}
              checked
              disabled
            />
          </Form.Group>
          {/* Cookies de rendimiento */}
          <Form.Group controlId="performanceCookies">
            <Form.Check
              type="checkbox"
              label={t('customizeCookiesModal.performance_cookies_label')}
              name="performance"
              checked={preferences.performance}
              onChange={handleChange}
            />
          </Form.Group>
          {/* Cookies de funcionalidad */}
          <Form.Group controlId="functionalityCookies">
            <Form.Check
              type="checkbox"
              label={t('customizeCookiesModal.functionality_cookies_label')}
              name="functionality"
              checked={preferences.functionality}
              onChange={handleChange}
            />
          </Form.Group>
          {/* Cookies de publicidad */}
          <Form.Group controlId="advertisingCookies">
            <Form.Check
              type="checkbox"
              label={t('customizeCookiesModal.advertising_cookies_label')}
              name="advertising"
              checked={preferences.advertising}
              onChange={handleChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t('common.cancel')}
        </Button>
        <Button onClick={handleSave}>
          {t('common.save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomizeCookiesModal;