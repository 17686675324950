// src/components/forms/UserWallet.jsx
import React, { useState, useEffect } from 'react';
import { Toast, ToastContainer, Spinner, Alert, Card, Table, Badge, OverlayTrigger, Tooltip, Button, Modal, Form,  InputGroup, FormControl } from 'react-bootstrap';
import WithdrawFundsModal from '../modals/WithdrawFundsModal';
import walletService from '../../services/walletService';
import { formatDate } from '../../utils/formatDate';
import GiftWishModal from '../modals/GiftWishModal';
import ModalLoading from '../modals/ModalLoading';
import { useTranslation } from 'react-i18next';
import { MdVerified } from 'react-icons/md';
import useAuth from '../../hooks/useAuth';


const UserWallet = () => {
  const { t } = useTranslation();

  const { user, refreshUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  
  const [walletData, setWalletData] = useState(null);
  const [rewards, setRewards] = useState([]);
  const [transactions, setTransactions] = useState([]);

  // Estados para el Modal de Retiro
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [withdrawError, setWithdrawError] = useState('');
  const [withdrawSuccess, setWithdrawSuccess] = useState('');
  const [withdrawLoading, setWithdrawLoading] = useState(false);
  const language = localStorage.getItem('language') || 'en';

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const [showGiftModal, setShowGiftModal] = useState(false);
  const [giftError, setGiftError] = useState('');
  const [giftSuccess, setGiftSuccess] = useState('');
  const [giftLoading, setGiftLoading] = useState(false);

  const [wishGifted, setWishGifted] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const [walletResponse, rewardsResponse, transactionsResponse] = await Promise.all([
          walletService.getWallet(),
          walletService.getRewards(),
          walletService.getTransactions()
        ]);

        if (!walletResponse.data.status) {
          throw new Error(t('UserWallet.error_getting_wallet'));
        }
        if (!rewardsResponse.data.status) {
          throw new Error(t('UserWallet.error_getting_rewards'));
        }
        if (!transactionsResponse.data.status) {
          throw new Error(t('UserWallet.error_getting_transactions'));
        }

        setWalletData(walletResponse.data.data);
        setRewards(rewardsResponse.data.data);
        setTransactions(transactionsResponse.data.data);

      } catch (err) {
        setError(err.message || t('UserWallet.error_generic'));
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [t]);


  // Función para abrir el Modal de Retiro
  const handleOpenWithdrawModal = () => {
    setShowWithdrawModal(true);
    setWithdrawError('');
    setWithdrawSuccess('');
  };

  // Función para cerrar el Modal de Retiro
  const handleCloseWithdrawModal = () => {
    setShowWithdrawModal(false);
    setWithdrawError('');
    setWithdrawSuccess('');
  };

  // Función para manejar el retiro de fondos
  const handleWithdraw = async (amount) => {
    setWithdrawLoading(true);
    setWithdrawError('');
    setWithdrawSuccess('');

    const numericAmount = parseFloat(amount);

    // Validaciones básicas
    if (isNaN(numericAmount) || numericAmount <= 0) {
      setWithdrawError(t('UserWallet.invalid_amount'));
      setWithdrawLoading(false);
      return;
    }

    if (numericAmount > parseFloat(walletData.balance)) {
      setWithdrawError(t('UserWallet.amount_exceeds_balance'));
      setWithdrawLoading(false);
      return;
    }

    try {
      const response = await walletService.postTransactions({ amount: numericAmount });

      if (response.data.status) {
        setWithdrawSuccess(t('UserWallet.withdraw_request_success'));
        // Actualizar los datos del wallet después del retiro
        const updatedWalletResponse = await walletService.getWallet();
        const updatedTransactionsResponse = await walletService.getTransactions();
        setWalletData(updatedWalletResponse.data.data);
        setTransactions(updatedTransactionsResponse.data.data);
        setShowWithdrawModal(false);
      } else {
        setWithdrawError(t('errors.withdraw_error'));
      }
    } catch (err) {
      // setWithdrawError(err.message || t('UserWallet.withdraw_error'));
      setWithdrawError(t(`errors.${err.response?.data?.message}`) || t('errors.error_on_withdraw'));
    } finally {
      setWithdrawLoading(false);
    }
  };


  if (loading) {
    return (
      <ModalLoading
        show={loading}
        message={t('UserWallet.loadin_wallet')}
        barSize={8}
      />
    );
  }

  if (error) {
    return (
      <Alert variant="danger" className="mt-4">
        {error}
      </Alert>
    );
  }


  const renderVerificationStatus = (isVerified, t) => {
    const iconStyle = {
      marginLeft: '5px',
      verticalAlign: 'middle',
      cursor: 'pointer',
    };

    const tooltipText = isVerified ? t('UserWallet.user_verified') : t('UserWallet.user_not_verified');

    return (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={`tooltip-verified-${isVerified}`}>{tooltipText}</Tooltip>}
      >
        {/* Envolvemos el ícono en un <span> con estilos para ampliar el área de hover */}
        <span style={{ display: 'inline-block', padding: '4px' }}>
          {isVerified ? (
            <MdVerified color="green" size={16} style={iconStyle} aria-label={tooltipText} />
          ) : (
            <MdVerified color="grey" size={16} style={iconStyle} aria-label={tooltipText} />
          )}
        </span>
      </OverlayTrigger>
    );
  };


  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setToastMessage(t('common.copied_to_clipboard'));
      setShowToast(true);
    } catch (err) {
      console.error('Failed to copy!', err);
      setToastMessage(t('common.copy_failed'));
      setShowToast(true);
    }
  };


  // Abrir el modal de regalo
  const handleOpenGiftModal = () => {
    setShowGiftModal(true);
    setGiftError('');
    setGiftSuccess('');
  };

  // Cerrar el modal de regalo
  const handleCloseGiftModal = () => {
    setShowGiftModal(false);
    setGiftError('');
    setGiftSuccess('');
  };

  // Manejar la acción de regalar un deseo
  const handleGiftWish = async (receiverEmail) => {
    setGiftLoading(true);
    setGiftError('');
    setGiftSuccess('');

    try {

      const email_subject = t('email.gifted_wish_email_subject');
      const email_body = t('email.gifted_wish_email_body');

      const response = await walletService.giftAWish({ receiver_email: receiverEmail, email_subject, email_body });

      if (response.data.status) {
        setGiftSuccess(t('UserWallet.gift_success'));

        // Actualizar los datos del wallet
        const [walletResponse, rewardsResponse, transactionsResponse] = await Promise.all([
          walletService.getWallet(),
          walletService.getRewards(),
          walletService.getTransactions()
        ]);

        setWalletData(walletResponse.data.data);
        setRewards(rewardsResponse.data.data);
        setTransactions(transactionsResponse.data.data);

        setWishGifted(true);
        
        //setShowGiftModal(false);

      } else {
        setGiftError(t(`errors.${response.data.message}`) || t('errors.gift_error'));
      }
    } catch (err) {
      setGiftError(t(`errors.${err.response?.data?.message}`) || t('errors.error_on_gift'));
    } finally {
      setGiftLoading(false);
    }
  };



  return (
    <div className="wallet-tab-container">

      {user?.data_user?.referral_code && (
        <div className="mt-2 mb-3 p-3 border rounded">
          <h5>{t('paymentSuccess.referral_title')}</h5>
          <p>{t('paymentSuccess.referral_description')}</p>
          <InputGroup className="mb-3">
            <FormControl
              readOnly
              value={`${window.location.origin}/${language}/?referral_code=${user?.data_user?.referral_code || ''}`}
              aria-label="Referral Link"
              aria-describedby="referral-link"
            />
            <Button 
              variant="outline-secondary" 
              onClick={() => copyToClipboard(`${window.location.origin}/${language}/?referral_code=${user?.data_user?.referral_code || ''}`)}
            >
              <i className="bi bi-clipboard"></i> {t('common.copy')}
            </Button>
          </InputGroup>
          <p className="text-muted">
            {t('paymentSuccess.your_referral_code_is')}: <strong>{`${user?.data_user?.referral_code}`}</strong>
          </p>
        </div>
      )}


      {/* Balance Card */}
      <Card className="mb-4 shadow-sm">
        <Card.Body className="d-flex justify-content-between align-items-start">
          {/* Sección de Balance */}
          <div>
            <Card.Title>{t('UserWallet.balance_title')}</Card.Title>
            <Card.Text style={{ fontSize: '2rem', fontWeight: 'bold' }}>
              {walletData?.balance} USD
            </Card.Text>
            <small className="text-muted">
              {t('UserWallet.last_update')}: {formatDate(walletData?.last_update)}
            </small>
          </div>

          {/* Sección de Botones */}
          <div className="d-flex flex-column align-items-end">
            
            <Button className="primary-custom-btn mb-2" onClick={handleOpenWithdrawModal}>
              {t('UserWallet.withdraw_funds')}
            </Button>

            { parseFloat(walletData?.balance) >= 1 && (
              <Button 
                className="secondary-custom-btn" 
                onClick={handleOpenGiftModal}
                disabled={parseFloat(walletData?.balance) < 1}
              >
                {t('UserWallet.gift_a_wish')}
              </Button>
            )}
              
          </div>
        </Card.Body>
      </Card>

      {/* Rewards */}
      <Card className="mb-4 shadow-sm">
        <Card.Body>
          <Card.Title>{t('UserWallet.rewards_and_withdrawals_title')}</Card.Title>
          {rewards.length === 0 ? (
            <p className="text-muted">{t('UserWallet.no_rewards')}</p>
          ) : (
            <Table responsive hover className="mb-0">
              <thead>
                <tr>
                  <th>{t('UserWallet.amount')}</th>
                  <th>{t('UserWallet.reason')}</th>
                  <th>{t('UserWallet.date')}</th>
                  <th>{t('UserWallet.referred_user')}</th>
                </tr>
              </thead>
              <tbody>
                {rewards.map((r) => (
                  <tr key={r.id}>
                    <td style={{ fontWeight: 'bold', color: r.amount.startsWith('-') ? 'red' : 'green' }}>
                      {r.amount} USD
                    </td>
                    <td>{ t(`UserWallet.${r.reason}`)  || t(`common.undefined`)}</td>
                    <td>{formatDate(r.created_at)}</td>
                    <td>
                      {r.referred_user ? (
                        <>
                          {r.referred_user.name} 
                          {renderVerificationStatus(r.referred_user.email_verified_at, t)}
                        </>
                      ) : '-'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Card.Body>
      </Card>

      {/* Transactions (Withdrawals) */}
      <Card className="mb-4 shadow-sm">
        <Card.Body>
          <Card.Title>{t('UserWallet.transactions_title')}</Card.Title>
          {transactions.length === 0 ? (
            <p className="text-muted">{t('UserWallet.no_transactions')}</p>
          ) : (
            <Table responsive hover className="mb-0">
              <thead>
                <tr>
                  <th>{t('UserWallet.transaction_id')}</th>
                  <th>{t('UserWallet.amount')}</th>
                  <th>{t('UserWallet.status')}</th>
                  <th>{t('UserWallet.date')}</th>
                </tr>
              </thead>
              <tbody>
                {transactions.map((tx) => (
                  <tr key={tx.id}>
                    <td>#{tx.id}</td>
                    <td style={{ fontWeight: 'bold' }}>
                      {tx.amount} USD
                    </td>
                    <td>
                      {tx.status === 'pending' && <Badge bg="warning">{t('UserWallet.status_pending')}</Badge>}
                      {tx.status === 'approved' && <Badge bg="info">{t('UserWallet.status_approved')}</Badge>}
                      {tx.status === 'rejected' && <Badge bg="danger">{t('UserWallet.status_rejected')}</Badge>}
                      {tx.status === 'paid' && <Badge bg="success">{t('UserWallet.status_paid')}</Badge>}
                    </td>
                    <td>{formatDate(tx.created_at)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Card.Body>
      </Card>


      {/* Modal para Retirar Fondos */}
      <WithdrawFundsModal
        show={showWithdrawModal}
        handleClose={handleCloseWithdrawModal}
        handleWithdraw={handleWithdraw}
        withdrawLoading={withdrawLoading}
        withdrawError={withdrawError}
        withdrawSuccess={withdrawSuccess}
        availableBalance={walletData?.balance}
      />

      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
          bg="success"
        >
          <Toast.Body className="text-white">{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>

      <GiftWishModal
        show={showGiftModal}
        handleClose={handleCloseGiftModal}
        handleGift={handleGiftWish}
        giftLoading={giftLoading}
        giftError={giftError}
        setGiftError={setGiftError}
        giftSuccess={giftSuccess}
        wishGifted={wishGifted}
      />

    </div>
  );
};

export default UserWallet;