import React from 'react';
import ReactDOM from 'react-dom';
import LoadingWave from '../common/LoadingWave';
import '../../assets/styles/ModalLoading.css';

const ModalLoading = ({ show, message = '', barSize = 5 }) => {
  if (!show) return null;

  return ReactDOM.createPortal(
    <div className="modal-overlay">
      <div className="loading-modal">
        <LoadingWave barSize={barSize} />
        {message && <p className="loading-message">{message}</p>}
      </div>
    </div>,
    document.getElementById('modal-root')
  );
};

export default ModalLoading;