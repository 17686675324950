// src/services/twofaService.js
import API from '../api';

const twofaService = {
	/**
	 * Verify two factor authentication
	 * @param {string} code
	 * @returns {Promise}
	 */
	verify: (code) => {
		return API.post('/verify-two-factor', { code });
	},

	/**
	 * Request two factor authentication
	 * @param {string} two_factor_type email|sms
	 * @param {string} purpose setting|login
	 * @returns {Promise}
	 * */
	request: (two_factor_type, purpose, email_subject=null, email_body=null) => {
		return API.post('/request-two-factor', { two_factor_type, purpose, email_subject, email_body });	
	},

	/**
	 * Enable two factor authentication
	 * @param {string} code email|sms
	 * @returns {Promise}
	 * */
	enable: (code) => {
		return API.post('/enable-two-factor', { code });	
	},

	/**
	 * Disable two factor authentication
	 * 
	 * @returns {Promise}
	 * */
	disable: () => {
		return API.post('/disable-two-factor');	
	},

	/**
	 * Email verification code
	 * 
	 * @returns {Promise}
	 * */
	email: () => {
		return API.post('/email-verification-code');	
	},

	/**
	 * Email verification to update email
	 * @param {string} email
	 * @returns {Promise}
	 * */
	emailUpdate: (email) => {
		return API.post('/email-verification-to-update', { email });	
	},

	/**
	 * Email verification process
	 * @param {string} verification_code
	 * @returns {Promise}
	 * */
	emailVerify: (verification_code) => {
		return API.post('/email-verification-process', { verification_code });	
	},

	/**
	 * Captcha verification code
	 * 
	 * @returns {Promise}
	 * */
	captcha: () => {
		return API.get('/captcha');	
	}
};

export default twofaService;