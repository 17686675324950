// src/components/modals/TwoFAModal.jsx
import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button, Form, Alert, Spinner, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import '../../assets/styles/TwoFAModal.css';

const TwoFAModal = ({
  show,
  handleClose,
  handleValidate,
  twoFALoading,
  twoFAError
}) => {
  const [twoFACodeArray, setTwoFACodeArray] = useState(['', '', '', '', '', '']);
  const { t } = useTranslation();

  // Refs para los inputs
  const inputRefs = useRef([]);

  // Función para manejar el cambio en los inputs
  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^\d?$/.test(value)) {
      const newCodeArray = [...twoFACodeArray];
      newCodeArray[index] = value;
      setTwoFACodeArray(newCodeArray);

      if (value && index < 5) {
        // Mover el foco al siguiente input
        inputRefs.current[index + 1].focus();
      }

      if (!value && index > 0) {
        // Si se borra el valor, mover el foco al input anterior
        inputRefs.current[index - 1].focus();
      }
    }
  };

  // Manejar la tecla Backspace para moverse al campo anterior
  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !twoFACodeArray[index] && index > 0) {
      const newCodeArray = [...twoFACodeArray];
      newCodeArray[index - 1] = '';
      setTwoFACodeArray(newCodeArray);
      inputRefs.current[index - 1].focus();
    }
  };

  // Concatenar los valores y llamar a handleValidate cuando se haga clic en "Validar"
  const onValidate = () => {
    const code = twoFACodeArray.join('');
    handleValidate(code);
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('Text');
    if (/^\d+$/.test(pasteData)) {
      const pasteArray = pasteData.slice(0, 6).split('');
      const newCodeArray = [...twoFACodeArray];
      for (let i = 0; i < pasteArray.length; i++) {
        newCodeArray[i] = pasteArray[i];
      }
      setTwoFACodeArray(newCodeArray);
      const lastIndex = Math.min(pasteArray.length - 1, 5);
      inputRefs.current[lastIndex].focus();
    }
  };

  // Reiniciar el código y referencias al cerrar el modal
  useEffect(() => {
    if (!show) {
      setTwoFACodeArray(['', '', '', '', '', '']);
    }
  }, [show]);

  return ReactDOM.createPortal(
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('twoFAModal.2fa_verification_title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {t('twoFAModal.2fa_verification_description')}
        </p>
        <Form>
          <Row className="justify-content-center">
            {twoFACodeArray.map((value, index) => (
              <Col xs="2" key={index}>
                <Form.Control
                  type="text"
                  maxLength={1}
                  value={value}
                  onChange={(e) => handleChange(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  ref={(el) => (inputRefs.current[index] = el)}
                  autoFocus={index === 0}
                  className="text-center two-fa-input"
                  inputMode="numeric"
                  autoComplete="one-time-code"
                  disabled={twoFALoading}
                  {...(index === 0 && { onPaste: handlePaste })}
                />
              </Col>
            ))}
          </Row>
          {twoFAError && (
            <Alert variant="danger" className="mt-3">
              {twoFAError}
            </Alert>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={onValidate}
          disabled={twoFALoading || twoFACodeArray.includes('')}
        >
          {twoFALoading ? <Spinner animation="border" size="sm" /> : t('common.validate')}
        </Button>
        <Button
          variant="secondary"
          onClick={handleClose}
          disabled={twoFALoading}
        >
          {t('common.cancel')}
        </Button>
      </Modal.Footer>
    </Modal>,
    document.getElementById('modal-root')
  );
};

export default TwoFAModal;