// src/pages/HomePage.jsx
import React, { useEffect, useState, useRef } from 'react';
import TopWishesMobileButton from '../components/specific/TopWishesMobileButton';
import verificationService from '../services/verificationService';
import { useLocation, useSearchParams } from 'react-router-dom';
import MessageModal from '../components/modals/MessageModal';
import ModalLoading from '../components/modals/ModalLoading';
import MainLayout from '../components/layout/MainLayout';
import TopWishes from '../components/specific/TopWishes';
import WishForm from '../components/specific/WishForm';

import WishList from '../components/specific/WishList';
import useWindowWidth from '../hooks/useWindowWidth';
import wishService from '../services/wishService';
import { useTranslation } from 'react-i18next';
// import { Spinner } from 'react-bootstrap';
import SEO from '../components/common/SEO';
import '../assets/styles/HomePage.css';

const HomePage = () => {
  // Estados para todos los deseos
  const { t } = useTranslation();
  const location = useLocation();
  const [wishes, setWishes] = useState([]);
  const [loadingWishes, setLoadingWishes] = useState(true);
  const [errorWishes, setErrorWishes] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const language = localStorage.getItem('language') || 'en';
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const verificationAttemptedRef = useRef(false);
  
  const [loadingModalWave, setLoadingModalWave] = useState(false);
  const [loadingModalWaveText, setLoadingModalWaveText] = useState(null);

  // Estados para los top deseos
  // const [topWishes, setTopWishes] = useState([]);
  // const [loadingTopWishes, setLoadingTopWishes] = useState(true);
  // const [errorTopWishes, setErrorTopWishes] = useState(null);

  const [showMessageModal, setShowMessageModal] = useState(false);
  const [messageModalType, setMessageModalType] = useState('info');
  const [messageModalContent, setMessageModalContent] = useState('');

  const width = useWindowWidth();
  const isMobile = width < 768;
  
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('q') || '';

  useEffect(() => {
    // Función para obtener todos los deseos
    const fetchAllWishes = async () => {
      try {
        setLoadingWishes(true);
        let response;

        if (searchQuery) {
          // Realizar búsqueda
          response = await wishService.searchWishes(searchQuery);
        } else {
          // Obtener todos los deseos
          response = await wishService.getAllWishes();
        }

        if (response.data.status) {
          setWishes(response.data.data.wishes.data);
          setCurrentPage(response.data.data.wishes.current_page);
          setLastPage(response.data.data.wishes.last_page);
        } else {
          setErrorWishes(new Error(t('common.error_loading_wishes')));
        }
      } catch (err) {
        setErrorWishes(err);
      } finally {
        setLoadingWishes(false);
      }
    };

    fetchAllWishes();
  }, [searchQuery, t]);

  // useEffect(() => {
  //   // Función para obtener los top deseos
  //   const fetchTopWishes = async () => {
  //     try {
  //       setLoadingTopWishes(true);
  //       const response = await wishService.getTopWishes();
  //       if (response.data.status) {
  //         setTopWishes(response.data.data.wishes);
  //       } else {
  //         console.error("Error obteniendo top deseos.");
  //         setErrorTopWishes(new Error("Error obteniendo top deseos."));
  //       }
  //     } catch (err) {
  //       console.error("Error al obtener top deseos:", err);
  //       setErrorTopWishes(err);
  //     } finally {
  //       setLoadingTopWishes(false);
  //     }
  //   };

  //   fetchTopWishes();
  // }, []);


  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const email = params.get('email');
    const token = params.get('token');

    const verifyEmail = async () => {
      try {
        const response = await verificationService.emailVerificationProcess({ email, token });
        if (response.data.status) {
          setLoadingModalWave(false);
          setMessageModalType('info');
          setMessageModalContent(t('email_verification.success_message') + ' - ' + t(`email_verification.${response.data.message}`) );
        } else {
          setLoadingModalWave(false);
          setLoadingModalWaveText(null);
          setMessageModalType('error');
          setMessageModalContent(t('errors.error_message_on_email_verification') + ' - ' + response.data.message);
        }
      } catch (err) {
        const errors = err.response.data.message;
        setMessageModalType('error');
        setMessageModalContent(t('errors.error_message_while_verifying_email') + ' - ' + t(`errors.${errors}`));
      } finally {
        setLoadingModalWave(false);
        setLoadingModalWaveText(null);
        setShowMessageModal(true);
      }
    };


    if (email && token && !verificationAttemptedRef.current && location.pathname.includes('email-verification-process')) {
      setLoadingModalWave(true);
      setLoadingModalWaveText(t('email_verification.loading_message'));
      verificationAttemptedRef.current = true;
      verifyEmail();
    } else {
      console.log('La verificación del correo electrónico ya se ha intentado');
    }
    
  }, [location, t]);


  // Función para actualizar un deseo (por ejemplo, likes, hearts)
  const updateWish = (updatedWish) => {
    setWishes((prevWishes) =>
      prevWishes.map((wish) =>
        wish.id === updatedWish.id ? updatedWish : wish
      )
    );
    // setTopWishes((prevTopWishes) =>
    //   prevTopWishes.map((wish) =>
    //     wish.id === updatedWish.id ? updatedWish : wish
    //   )
    // );
  };

  const loadMoreWishes = async () => {
    if (currentPage >= lastPage || loadingMore) return; 

    try {
      setLoadingMore(true);
      const nextPage = currentPage + 1;
      const response = await wishService.getAllWishes(nextPage);

      if (response.data.status) {
        setWishes((prev) => [...prev, ...response.data.data.wishes.data]);
        setCurrentPage(response.data.data.wishes.current_page);
      }
    } catch (err) {
      console.error('Error cargando más deseos:', err);
    } finally {
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 200
      ) {
        loadMoreWishes();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [currentPage, lastPage, loadingMore]);

  return (
    <MainLayout>

      <SEO
        titleKey="seo.homepage.title"
        descriptionKey="seo.homepage.description"
        keywordsKey="seo.homepage.keywords"
        lang={language}
        url={`${window.location.origin}/${language}`}
        image={`${window.location.origin}/images/homepage-banner.jpg`}
        siteTwitter="@andres_elias"
        authorTwitter="@andres_elias"
        localeAlternates={['en_US', 'es_ES']}
        structuredData={{
          '@context': 'https://schema.org',
          '@type': 'WebPage',
          name: t('seo.homepage.title'),
          description: t('seo.homepage.description_structured_data'),
          url: `${window.location.origin}/${language}`,
          mainEntity: {
            '@type': 'ItemList',
            itemListElement: wishes.slice(0, 5).map((wish, index) => ({
              '@type': 'ListItem',
              position: index + 1,
              item: {
                '@type': 'CreativeWork',
                name: wish.content,
                author: {
                  '@type': 'Person',
                  name: wish.user?.name || 'Usuario anónimo',
                },
                datePublished: wish.created_at,
                interactionStatistic: [
                  {
                    '@type': 'InteractionCounter',
                    interactionType: 'https://schema.org/LikeAction',
                    userInteractionCount: wish.likes || 0,
                  },
                  {
                    '@type': 'InteractionCounter',
                    interactionType: 'https://schema.org/ReactAction',
                    userInteractionCount: wish.hearts || 0,
                  },
                ],
              },
            })),
          },
        }}
      />

      <div className="main-container">
        {/* Left Sidebar */}
        <aside className="left-sidebar">
          <WishForm />
        </aside>

        {/* Wish Collage */}
        <WishList 
          wishes={wishes} 
          onUpdateWish={updateWish} 
          loading={loadingWishes} 
          error={errorWishes} 
          loadingMore={loadingMore}
          lastPageReached={currentPage >= lastPage}
        />

        {/* Right Sidebar */}
        <aside className="right-sidebar">
          {!isMobile && (
            <TopWishes 
              // topWishes={topWishes}
              wishes={wishes} 
              onUpdateWish={updateWish} 
              // loading={loadingTopWishes} 
              loading={loadingWishes}
              // error={errorTopWishes} 
              error={errorWishes}
            />
          )}

          {isMobile && (
            <TopWishesMobileButton
              wishes={wishes}
              onUpdateWish={updateWish}
              loading={loadingWishes}
              error={errorWishes}
            />
          )}
        </aside>


        <MessageModal
          show={showMessageModal}
          type={messageModalType}
          message={messageModalContent}
          onClose={() => setShowMessageModal(false)}
        />

        <ModalLoading
          show={loadingModalWave}
          message={loadingModalWaveText}
          barSize={8}
        />

      </div>
    </MainLayout>
  );
};

export default HomePage;

