// src/components/modals/LoginModal.jsx
import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import twofaService from '../../services/twofaService';
// import { CONSTANTS } from '../../config/constants';
import LoadingWave from '../common/LoadingWave';
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/useAuth';
import TwoFAModal from './TwoFAModal';
import '../../assets/styles/AuthModal.css';

const LoginModal = ({ show, onClose }) => {
  const { t } = useTranslation();
  const { login, completeLogin } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [captchaToken, setCaptchaToken] = useState('');
  const [captchaImageUrl, setCaptchaImageUrl] = useState('');
  const [error, setError] = useState('');
  const [captchaLoaded, setCaptchaLoaded] = useState(false);
  const [captchaRendered, setCaptchaRendered] = useState(false);


  // Estado para manejar el modal de 2FA
  const [showTwoFAModal, setShowTwoFAModal] = useState(false);
  const [twoFAError, setTwoFAError] = useState('');
  const [twoFALoading, setTwoFALoading] = useState(false);


  // Estado de carga para el proceso de login
  const [loading, setLoading] = useState(false);


  // Función para cargar la imagen del captcha
  const loadCaptcha = useCallback(async () => {
    try {
      const response = await twofaService.captcha();
      setCaptchaImageUrl(response.data.captcha_image);
      setCaptchaToken(response.data.captcha_token);
      setCaptchaLoaded(true);
    } catch (err) {
      setError(t('loginModal.error_loading_captcha'));
    }
  }, [t]);


  useEffect(() => {
    if (show && !captchaLoaded) {
      loadCaptcha();
    }
  }, [show, captchaLoaded, t, loadCaptcha]);


  useEffect(() => {
    if (show) {
      setCaptchaLoaded(false);
      setCaptchaRendered(false);
      loadCaptcha();
    }
  }, [show, loadCaptcha]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {

      const email_subject = t('email.2fa_request_subject');
      const email_body = t('email.2fa_request_body');

      const response = await login({ email, password, captcha, captchaToken, email_subject, email_body });
      if (response.requireCode) {
        setShowTwoFAModal(true);
      } else {
        setEmail('');
        setPassword('');
        onClose();
        setCaptchaLoaded(false);
      }

      loadCaptcha();

    } catch (err) {
      setError(t(`errors.${err.response?.data?.message}`) || t('loginModal.error_on_login'));
      loadCaptcha();

    } finally {
      setLoading(false);
    }
  };



  // Función para manejar la validación del código de 2FA
  const handleValidateTwoFA = async (code) => {
    setTwoFALoading(true);
    setTwoFAError('');
    try {
      const response = await twofaService.verify(code);
      if (response.data.code === 200) {
        completeLogin();
        setShowTwoFAModal(false);
        setEmail('');
        setPassword('');
        onClose();
      } else {
        setTwoFAError(t('loginModal.invalid_2fa_code'));
      }
    } catch (err) {
      setTwoFAError(err.response?.data?.message || t('loginModal.error_validating_2fa_code'));
    } finally {
      setTwoFALoading(false);
    }
  };



  // Evita que el clic dentro del modal cierre el modal
  const handleModalContentClick = (e) => {
    e.stopPropagation();
  };


  // Controlar la visibilidad del modal sin condicionar los hooks
  if (!show) return null;

  return ReactDOM.createPortal(
    <>
      <div className="modal" onClick={onClose}>
        <div className="modal-content" onClick={handleModalContentClick}>
          <h4>{t('loginModal.login_title')}</h4>

          {error && <div className="error-message">{error}</div>}

          <form onSubmit={handleSubmit}>
            <label>{t('loginModal.email_label')}</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <label>{t('loginModal.password_label')}</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />

            {/* Captcha */}
            <div className="captcha-container">
              {/*<label>Ingrese el texto de la imagen</label>*/}
              <label>{t('loginModal.enter_image_text')}</label>

              <div className="captcha-image-refresh">
                {/*<img src={captchaImageUrl} alt={t('loginModal.refresh_captcha_title')} className="captcha-image" />
                */}
                {!captchaLoaded ? (
                  <LoadingWave barSize={5} />
                ) : (
                  <img
                    src={captchaImageUrl}
                    alt={t('loginModal.refresh_captcha_title')}
                    className="captcha-image"
                    onLoad={() => setCaptchaRendered(true)}
                    onError={() => {
                      setCaptchaRendered(false);
                      setError(t('loginModal.error_loading_captcha'));
                    }}
                  />
                )}
                <button
                  type="button"
                  className="refresh-captcha-btn"
                  onClick={loadCaptcha}
                  title={t('loginModal.refresh_captcha_title')}
                >
                  &#x21bb;
                </button>
              </div>
              <input
                type="text"
                value={captcha}
                className="captcha-input"
                onChange={(e) => setCaptcha(e.target.value)}
                required
                placeholder={t('loginModal.captcha_placeholder')}
              />
            </div>

            <button className="login-btn" type="submit" disabled={loading}>
              {loading ? t('common.loading') : t('common.login')}
            </button>

          </form>
          
          <button className="close-btn" onClick={onClose}>
            {t('common.close')}
          </button>

        </div>
      </div>
      {/* Renderizar el TwoFAModal */}
      {showTwoFAModal && (
        <TwoFAModal
          show={showTwoFAModal}
          handleClose={() => setShowTwoFAModal(false)}
          handleValidate={handleValidateTwoFA}
          twoFALoading={twoFALoading}
          twoFAError={twoFAError}
        />
      )}
    </>,
    document.getElementById('modal-root')
  );
};

export default LoginModal;