// src/components/forms/SecurityForm.jsx
import React, { useState, useEffect } from 'react';
import { Form, Button, Alert, Spinner } from 'react-bootstrap';
import twofaService from '../../services/twofaService';
import authService from '../../services/authService';
import { useTranslation } from 'react-i18next';
import TwoFAModal from '../modals/TwoFAModal';
import useAuth from '../../hooks/useAuth';

const SecurityForm = () => {
  const { t } = useTranslation();
  const { user, refreshUser } = useAuth();

  // Estados para los campos del formulario
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // Estados para manejar la carga, errores y mensajes de éxito
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Estados para 2FA
  const [twoFAEnabled, setTwoFAEnabled] = useState(false);
  const [showTwoFAModal, setShowTwoFAModal] = useState(false);
  const [twoFALoading, setTwoFALoading] = useState(false);
  const [twoFAError, setTwoFAError] = useState('');


  useEffect(() => {
    if (user && user.data_user) {
      setTwoFAEnabled(user.data_user.two_factor_enabled === 1);
    }
  }, [user]);



  // Auto-dismiss de errores después de 5 segundos
  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError('');
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [error]);


  // Auto-dismiss de mensajes de éxito después de 5 segundos
  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage('');
      }, 5000); // 5 segundos

      return () => clearTimeout(timer);
    }
  }, [successMessage]);


  // Manejar el envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccessMessage('');

    // Validaciones básicas
    if (newPassword !== confirmPassword) {
      setError(t('securityForm.new_passwords_do_not_match'));
      setLoading(false);
      return;
    }

    try {
      await authService.updatePassword(
        currentPassword,
        newPassword,
        confirmPassword
      );
      setSuccessMessage(t('securityForm.password_updated_successfully'));
      // Opcional: Limpiar los campos del formulario
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      refreshUser();
    } catch (err) {
      // Manejo de errores
      if (err.message) {
        setError(err.message);
      } else if (err.errors) {
        // Manejar validaciones específicas
        const errorMessages = Object.values(err.errors).flat().join(' ');
        setError(errorMessages);
      } else {
        setError(t('securityForm.error_updating_password'));
      }
    } finally {
      setLoading(false);
    }
  };


  // Manejar el cambio del switch de 2FA
  const handleTwoFAToggle = async (e) => {
    const enabled = e.target.checked;

    if (enabled) {
      setShowTwoFAModal(true);

      try {
        await twofaService.request("email", "settings", t('email.2fa_request_subject'), t('email.2fa_request_body'));
      } catch (err) {
        setError(t('securityForm.error_getting_2fa_code'));
        setTwoFAEnabled(false);
        setShowTwoFAModal(false);
      }
    } else {
      try {
        await twofaService.disable();
        setTwoFAEnabled(false);
        setSuccessMessage(t('securityForm.2fa_disabled_successfully'));
      } catch (err) {
        setError(t('securityForm.error_disabling_2fa'));
        setTwoFAEnabled(true);
      }
    }
  };


  // Manejar la validación del código de 2FA
  const handleValidateTwoFA = async (code) => {
    setTwoFALoading(true);
    setTwoFAError('');

    try {
      const response = await twofaService.enable(code);
      if (response.data.status) {
        setSuccessMessage(t('securityForm.2fa_enabled_successfully'));
        setShowTwoFAModal(false);
        setTwoFAEnabled(true);
        refreshUser();
      } else {
        setTwoFAError(t('securityForm.verification_code_invalid'));
        setTwoFAEnabled(false);
      }
    } catch (err) {
      setTwoFAError(t('securityForm.error_verifying_2fa_code'));
      setTwoFAEnabled(false);
    } finally {
      setTwoFALoading(false);
    }
  };


  return (
    <>
      <Form onSubmit={handleSubmit}>
        <h3>{t('securityForm.account_security_label')}</h3>

        {/* Cambiar Contraseña */}
        <Form.Group className="mt-3" controlId="currentPassword">
          <Form.Label>{t('securityForm.current_password_label')}</Form.Label>
          <Form.Control
            type="password"
            placeholder={t('securityForm.current_password_placeholder')}
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            required
            autoComplete="current-password"
          />
        </Form.Group>

        <Form.Group className="mt-3" controlId="newPassword">
          <Form.Label>{t('securityForm.new_password_label')}</Form.Label>
          <Form.Control
            type="password"
            placeholder={t('securityForm.new_password_placeholder')}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
            autoComplete="new-password"
          />
        </Form.Group>

        <Form.Group className="mt-3" controlId="confirmPassword">
          <Form.Label>{t('securityForm.confirm_new_password_label')}</Form.Label>
          <Form.Control
            type="password"
            placeholder={t('securityForm.confirm_new_password_placeholder')}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            autoComplete="new-password"
          />
        </Form.Group>

        {/* 2FA */}
        <Form.Group className="mt-3" controlId="twoFA">
          <Form.Label>{t('securityForm.2fa_autentication_label')}</Form.Label>
          <Form.Check 
            type="switch" 
            label={twoFAEnabled ? t('securityForm.disable_2fa_label') : t('securityForm.enable_2fa_label')} 
            checked={twoFAEnabled} 
            onChange={handleTwoFAToggle} 
            autoComplete="off"
          />
        </Form.Group>

        {/* Mostrar errores o éxito */}
        {error && (
          <Alert 
            variant="danger" 
            className="mt-3" 
            dismissible 
            onClose={() => setError('')}
            transition
          >
            {error}
          </Alert>
        )}
        {successMessage && (
          <Alert 
            variant="success" 
            className="mt-3" 
            dismissible 
            onClose={() => setSuccessMessage('')}
            transition
          >
            {successMessage}
          </Alert>
        )}

        {/* Botón de Guardar Cambios */}
        <div className="form-actions mt-4 text-center">
          <Button variant="primary" type="submit" disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : t('common.save')}
          </Button>
        </div>
      </Form>


      <TwoFAModal
        show={showTwoFAModal}
        handleClose={() => {
          setShowTwoFAModal(false);
          setTwoFAEnabled(false);
        }}
        handleValidate={handleValidateTwoFA}
        twoFALoading={twoFALoading}
        twoFAError={twoFAError}
      />

    </>
  );
};

export default SecurityForm;