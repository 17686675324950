import React, { useState } from 'react';
import '../assets/styles/DevAuth.css';

const DevAuth = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem('devAuthEmail', email);
    window.location.href = '/';
  };

  return (
    <div className="dev-auth-container">
      <h1>QA Environment</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="email">E-Mail:</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit">Continue</button>
      </form>
    </div>
  );
};

export default DevAuth;