// src/utils/formatDate.js
export const formatDate = (isoString) => {
  if (!isoString) return '-';
  
  const options = {
    month: 'short',       // "Dec"
    day: '2-digit',       // "07"
    year: 'numeric',      // "2024"
    hour: '2-digit',      // "01"
    minute: '2-digit',    // "35"
    second: '2-digit',    // "31"
    hour12: false,        // Formato de 24 horas
  };
  
  const date = new Date(isoString);
  
  // Verificar si la fecha es válida
  if (isNaN(date.getTime())) {
    return '-';
  }
  
  const formattedDate = date.toLocaleString('en-US', options);
  
  // Remover la coma entre la fecha y la hora
  return formattedDate.replace(',', '');
};