// src/pages/Contact.jsx
import React, { useEffect, useState, useRef, useCallback } from 'react';
import TopWishesMobileButton from '../components/specific/TopWishesMobileButton';
import miscellaneousService from '../services/miscellaneousService';
import MainLayout from '../components/layout/MainLayout';
import TopWishes from '../components/specific/TopWishes';
import WishForm from '../components/specific/WishForm';
import useWindowWidth from '../hooks/useWindowWidth';
import twofaService from '../services/twofaService';
import wishService from '../services/wishService';
import { useTranslation } from 'react-i18next';
import SEO from '../components/common/SEO';
import '../assets/styles/StaticPageContent.css';
import '../assets/styles/Contact.css';

const Contact = () => {
  const { t } = useTranslation();
  const [wishes, setWishes] = useState([]);
  const [loadingWishes, setLoadingWishes] = useState(true);
  const [errorWishes, setErrorWishes] = useState(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [captchaToken, setCaptchaToken] = useState('');
  const [captchaImageUrl, setCaptchaImageUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const nameInputRef = useRef(null);
  const width = useWindowWidth();
  const isMobile = width < 768;
  const [captchaLoaded, setCaptchaLoaded] = useState(false);
  const language = localStorage.getItem('language') || 'en';

  useEffect(() => {
    // Función para obtener todos los deseos
    const fetchAllWishes = async () => {
      try {
        setLoadingWishes(true);
        const response = await wishService.getAllWishes();
        if (response.data.status) {
          setWishes(response.data.data.wishes.data);
        } else {
          setErrorWishes(new Error(t('common.error_loading_wishes')));
        }
      } catch (err) {
        setErrorWishes(err);
      } finally {
        setLoadingWishes(false);
      }
    };

    fetchAllWishes();
  }, [ t ]);

  // Función para actualizar un deseo (por ejemplo, likes, hearts)
  const updateWish = (updatedWish) => {
    setWishes((prevWishes) =>
      prevWishes.map((wish) =>
        wish.wishNumber === updatedWish.wishNumber ? updatedWish : wish
      )
    );
  };

  // Función para cargar la imagen del captcha
const loadCaptcha = useCallback(async () => {
  console.log('Captcha is being loaded');
  try {
    setCaptchaLoaded(false); // Asegúrate de establecer esto en falso antes de iniciar la carga
    const response = await twofaService.captcha();
    setCaptchaImageUrl(response.data.captcha_image);
    setCaptchaToken(response.data.captcha_token);
    setCaptchaLoaded(true); // Solo se establece en true después de una carga exitosa
  } catch (err) {
    setError(t('loginModal.error_loading_captcha'));
    setCaptchaLoaded(false); // Si ocurre un error, asegúrate de mantener el estado en falso
  }
}, [t]);

  useEffect(() => {
    if (!captchaLoaded) {
      loadCaptcha();
    }
  }, [captchaLoaded, t, loadCaptcha]);


  // Manejo del envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Limpiar mensajes previos
    setErrorMessage('');
    setSuccessMessage('');

    // Validación básica
    if (!name || !email || !subject || !message || !captcha || !captchaToken) {
      setErrorMessage(t('common.fill_all_required_fields'));
      return;
    }

    const data = {
      name,
      email,
      subject,
      message,
      captcha,
      captchaToken,
    };

    try {
      setIsSubmitting(true);
      const response = await miscellaneousService.sendContactMessage(data);

      if (response.data.status) {
        // Mensaje enviado exitosamente
        setSuccessMessage(t('common.message_sent_successfully'));

        // Limpiar el formulario
        setName('');
        setEmail('');
        setSubject('');
        setMessage('');
        setCaptcha('');
        setCaptchaToken('');
        setCaptchaImageUrl('');

        // Recargar el captcha
        await loadCaptcha();

        // Enfocar el primer campo
        if (nameInputRef.current) {
          nameInputRef.current.focus();
        }

        // Opcional: Limpiar el mensaje de éxito después de unos segundos
        setTimeout(() => {
          setSuccessMessage('');
        }, 5000);
      } else {
        // Mostrar mensaje de error
        setErrorMessage(t('common.error_sending_message'));
        setCaptchaLoaded(false);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 422 && error.response.data.errors) {
          const errors = error.response.data.errors;
          const errorMessages = Object.values(errors).flat().join(' ');
          setErrorMessage(errorMessages);
        } else if (error.response.data && error.response.data.message) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(t('common.error_sending_message'));
        }
      } else {
        setErrorMessage(t('common.error_sending_message'));
      }

      // Recargar el captcha en caso de error
      setCaptchaLoaded(false);
      await loadCaptcha();
    } finally {
      setIsSubmitting(false);
    }
  };


  return (
    <MainLayout>

      <SEO 
        titleKey="seo.contact.title"
        descriptionKey="seo.contact.description"
        keywordsKey="seo.contact.keywords"
        lang={language}
        url={`${window.location.origin}/${language}/contact`}
        image={`${window.location.origin}/images/contact-page-banner.jpg`}
        siteTwitter="@andres_elias"
        authorTwitter="@andres_elias"
        localeAlternates={['en_US', 'es_ES']}
        structuredData={{
          '@context': 'https://schema.org',
          '@type': 'ContactPage',
          name: t('seo.contact.title'),
          description: t('seo.contact.description'),
          url: `${window.location.origin}/${language}/contact`,
          mainEntity: {
            '@type': 'Organization',
            name: 'TheMillionWall',
            url: `${window.location.origin}`,
            contactPoint: {
              '@type': 'ContactPoint',
              telephone: '+34 638489644',
              contactType: 'customer service',
              email: 'info@themillionwall.com',
              areaServed: 'ES',
              availableLanguage: ['Spanish', 'English'],
            },
          },
        }}
      />

      <div className="main-container">
        {/* Left Sidebar */}
        <aside className="left-sidebar hide-on-mobile">
          <WishForm />
        </aside>

        {/* Center Content (Contact form) */}
        <section className="contact-content">
          <h2>{t('common.contact_title')}</h2>

          {error && <div className="error-message">{error}</div>}
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          {successMessage && <div className="success-message">{successMessage}</div>}

          <form onSubmit={handleSubmit}>
            <label htmlFor="name">{t('common.name_label')}</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder={t('common.name_placeholder')}
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              ref={nameInputRef}
            />

            <label htmlFor="email">{t('common.email_label')}</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder={t('common.email_placeholder')}
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <label htmlFor="subject">{t('common.subject_label')}</label>
            <input
              type="text"
              id="subject"
              name="subject"
              placeholder={t('common.subject_placeholder')}
              required
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />

            <label htmlFor="message">{t('common.message_label')}</label>
            <textarea
              id="message"
              name="message"
              rows="5"
              placeholder={t('common.message_placeholder')}
              required
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>

            {/* Captcha */}
            <div className="captcha-container">
              <label>{t('common.put_security_code_bellow')}</label>
              <div className="captcha-image-refresh">
                <img src={captchaImageUrl} alt={t('common.captcha_alt')} className="captcha-image" />
                <button
                  type="button"
                  className="refresh-captcha-btn"
                  onClick={() => {
                    setCaptchaLoaded(false); // Asegúrate de reiniciar el estado antes de volver a cargar
                    loadCaptcha();
                  }}
                  title={t('common.refresh_captcha')}
                >
                  &#x21bb;
                </button>
              </div>
              <input
                type="text"
                value={captcha}
                className="captcha-input"
                onChange={(e) => setCaptcha(e.target.value)}
                required
                placeholder={t('common.captcha_placeholder')}
              />
            </div>

            <button type="submit" disabled={isSubmitting}>
              {isSubmitting ? t('common.sending_message') : t('common.send_message')}
            </button>
          </form>
        </section>
        

        {/* Right Sidebar */}
        <aside className="right-sidebar">
          {!isMobile && (
            <TopWishes 
              wishes={wishes}
              onUpdateWish={updateWish}
              loading={loadingWishes}
              error={errorWishes}
            />
          )}

          {isMobile && (
            <TopWishesMobileButton
              wishes={wishes}
              onUpdateWish={updateWish}
              loading={loadingWishes}
              error={errorWishes}
            />
          )}
        </aside>

      </div>
    </MainLayout>
  );
};

export default Contact;