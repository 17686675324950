// src/hooks/useReferralCode.js
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useReferralCode = () => {
  const location = useLocation();
  const [referralCode, setReferralCode] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const codeFromURL = params.get('referral_code');

    if (codeFromURL) {
      localStorage.setItem('referral_code', codeFromURL);
      setReferralCode(codeFromURL);
      // window.history.replaceState({}, document.title, location.pathname);
    } else {
      const codeFromStorage = localStorage.getItem('referral_code');
      if (codeFromStorage) {
        setReferralCode(codeFromStorage);
      }
    }
  }, [location]);

  // Función para limpiar el referral_code del localStorage y del estado
  const clearReferralCode = () => {
    localStorage.removeItem('referral_code');
    setReferralCode('');
  };

  return { referralCode, clearReferralCode };
};

export default useReferralCode;