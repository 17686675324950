import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import useAuth from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import twofaService from '../../services/twofaService';
import { useLocation } from 'react-router-dom';
import '../../assets/styles/AuthModal.css';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import LoadingWave from '../common/LoadingWave';
import useReferralCode from '../../hooks/useReferralCode';

const RegisterModal = ({ show, onClose }) => {
  const { t } = useTranslation();
  const { register } = useAuth();
  const location = useLocation();
  const { referralCode, clearReferralCode } = useReferralCode();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password_confirmation, setPasswordConfirmation] = useState('');
  const [error, setError] = useState('');
  const [country, setCountry] = useState('');
  const [countryCallingCode, setCountryCallingCode] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [captchaToken, setCaptchaToken] = useState('');
  const [captchaImageUrl, setCaptchaImageUrl] = useState('');
  const [captchaLoaded, setCaptchaLoaded] = useState(false);
  const [captchaRendered, setCaptchaRendered] = useState(false);

  const tooltipRef = useRef();

  // Efecto para obtener la información del país cuando se muestra el modal
  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        setCountry(data.country_name);
        setCountryCallingCode(data.country_calling_code);
        setCountryCode(data.country_code);
      } catch (err) {
        setCountry('');
      }
    };

    if (show) {
      fetchCountry();
    }
  }, [show]);

  // Función para cargar la imagen del captcha
  const loadCaptcha = async () => {
    try {
      setCaptchaLoaded(false);
      setCaptchaRendered(false);
      const response = await twofaService.captcha();
      setCaptchaImageUrl(response.data.captcha_image);
      setCaptchaToken(response.data.captcha_token);
      setCaptchaLoaded(true);
    } catch (err) {
      setError(t('registerModal.error_loading_captcha'));
    }
  };

  useEffect(() => {
    if (show) {
      loadCaptcha();
    }
  }, [show]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== password_confirmation) {
      setError(t('registerModal.password_does_not_match'));
      return;
    }
    try {
      await register({
        name,
        email,
        password,
        password_confirmation,
        country,
        countryCallingCode,
        countryCode,
        captcha,
        captchaToken,
        referral_code: referralCode,
      });
      clearReferralCode();
      onClose();
    } catch (err) {
      const errorMessage = err.message || t('registerModal.error_registering');
      const responseMessage = err.response?.data?.message;
      const combinedMessage = responseMessage
        ? `${errorMessage} -> ${responseMessage}`
        : errorMessage;
      setError(combinedMessage);
    }
  };

  const renderTooltip = (props) => {
    return (
      <Tooltip className="auth-tooltip" id="referral-code-tooltip" {...props}>
        {t('registerModal.referral_code_help_text')}
      </Tooltip>
    );
  };

  const handleModalContentClick = (e) => {
    e.stopPropagation();
  };

  if (!show) return null;

  return ReactDOM.createPortal(
    <div className="modal" onClick={onClose}>
      <div className="modal-content" onClick={handleModalContentClick}>
        <h4>{t('registerModal.register_title')}</h4>

        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit}>
          <label>{t('registerModal.name_label')}</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />

          <label>{t('registerModal.email_label')}</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <label>{t('registerModal.password_label')}</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />

          <label>{t('registerModal.password_confirmation_label')}</label>
          <input
            type="password"
            value={password_confirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            required
          />

          {referralCode && (
            <>
              <label className="referral-label">
                {t('registerModal.referral_code_label')}
                <OverlayTrigger
                  container={document.getElementById('modal-root')}
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                >
                  <span
                    className="auth-tooltip-icon"
                    style={{ marginLeft: '8px', cursor: 'pointer', color: '#007bff' }}
                  >
                    &#x3F;
                  </span>
                </OverlayTrigger>
              </label>
              <Form.Control type="text" name="referralCode" value={referralCode} readOnly />
            </>
          )}

          {/* Captcha */}
          <div className="captcha-container">
            <label>{t('registerModal.enter_image_text')}</label>
            <div className="captcha-image-refresh">
              {!captchaLoaded ? (
                <LoadingWave barSize={5} />
              ) : (
                <img
                  src={captchaImageUrl}
                  alt="Captcha"
                  className="captcha-image"
                  onLoad={() => setCaptchaRendered(true)}
                  onError={() => {
                    setCaptchaRendered(false);
                    setError(t('registerModal.error_loading_captcha'));
                  }}
                />
              )}
              <button
                type="button"
                className="refresh-captcha-btn"
                onClick={loadCaptcha}
                title={t('registerModal.refresh_captcha_title')}
              >
                &#x21bb;
              </button>
            </div>
            <input
              type="text"
              value={captcha}
              className="captcha-input"
              onChange={(e) => setCaptcha(e.target.value)}
              required
              placeholder={t('registerModal.captcha_placeholder')}
            />
          </div>

          <button className="register-btn" type="submit">
            {t('common.register')}
          </button>
        </form>
        <button className="close-btn" onClick={onClose}>
          {t('common.close')}
        </button>
      </div>
    </div>,
    document.getElementById('modal-root')
  );
};

export default RegisterModal;